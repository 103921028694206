import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { Outlet, Navigate } from 'react-router-dom';

const CompanyFlagRoute = ({
  allowedFlag,
  element,
  redirectPath = '/go-premium/no-access',
}: {
  allowedFlag: 'can_trade_shares' | 'is_admin';
  element: JSX.Element;
  redirectPath?: string;
}) => {
  const { fullCompanyData } = useSelectedCompanyStore((state) => state);

  if (!fullCompanyData?.[allowedFlag]) {
    return <Navigate to={redirectPath} replace />;
  }

  return element ?? <Outlet />;
};

export default CompanyFlagRoute;
