export default {
  vesting: {
    captable: 'Captable',
    vestingSchedules: 'Vesting Schedules',
    vestingSchedule: 'Vesting Schedule',
    description:
      'Set a vesting schedule to give your employees full ownership rights to employer-provided assets over time',
    addVestingSchedules: 'Add Vesting Schedules',
    editVestingSchedule: 'Edit Vesting Schedule',
    emptyStateDescription:
      'In the next step, you will be able to create plans and pools In order to grant options to your employees, advisors, etc. We recommend preparing all the information regarding Options & ESOPs before clicking on the button.',

    vestingTable: {
      name: 'Vesting Name',
      duration: 'Duration',
      cliff: 'Cliff',
      upfrontVesting: 'Upfront Vesting',
      actions: 'Actions',
    },

    form: {
      vestingScheduleName: 'Vesting Schedule Name',
      vestingDuration: 'Vesting Duration (Months)',
      vestingFrequency: 'Vesting Frequency (Months)',
      vestingFrequencyHint: 'The frequency will occur at the end of the month',
      upfrontVesting: 'Upfront Vesting',
      upfrontVestingShares: 'Upfront Vesting (percentage)',
      vestingCliff: 'Vesting Cliff',
      Cliff: 'Cliff (Months)',
      CustomAmountVestedAtCliff: 'Custom Amount Vested At Cliff',
      vestedAtACliff: 'Vested At A Cliff (percentage)',
      comment: 'Comment',
      commentPlaceholder: 'leave any comment or note',
      submit: 'Submit',
      back: 'Back',
    },

    details: {
      details: 'Vesting Schedule Details',
      vestingDuration: 'Vesting Duration',
      vestingFrequency: 'Vesting Frequency',
      amountVestedAtCliff: 'Amount Vested At Cliff',
      Cliff: 'Cliff',
      upfrontVestingAmount: 'Upfront Vesting Ammount',
      comment: 'Comment',
      close: 'Close',
      months: 'Months',
      createdDate: 'Created Date',
    },
  },

  overview: {
    dashboard: 'Dashboard',
    personalHoldings: 'Personal Holdings',
    commonShares: 'Common Shares',
    preferredShares: 'Preferred Shares',
    convertibleInstrument: 'Convertible Instrument',
    authorizedShares: 'Authorized Shares',
    sharesIssued: 'Shares Issued',
    availableShares: 'Available Shares',
    invitedStakeholders: 'Shareholders',
    invitationsAccepted: 'Invitation(s) Accepted',
    shareholdersInvited: 'Shareholder(s) Invited',
    captable: 'Cap Table',
    captableOverview: 'Cap Table Overview',
    descriptionForHowItWorks: 'Description for how it works',
    addNewEquity: 'Add New Class',
    class: 'Class',
    shares: 'Shares',
    ownershipFDPercentage: 'Ownership (FD) %',
    ownershipPercentage: 'Ownership %',
    optionsAvailableToGrant: 'Options available to grant',
    total: 'Total',
    convertibleInstruments: 'Convertible Instruments',
    addNewInstruments: 'Add New Instruments',
    viewMore: 'View more',
    principal: 'Principal',
    interest: 'Interest',
    valuationCap: 'Valuation Cap',
    discountPercentage: 'Discount (%)',
    valuationHistory: 'Valuation History',
    latestValuation: 'Latest Valuation',
    totalCapitalRaised: 'Total capital raised',
    quickActions: 'Quick Actions',
    addEquity: 'Add Equity',
    recommendPreparation:
      'We recommend you prepare the following list of information that will be helpful during the next step',
    ensureRecentDocuments:
      'Make sure you are using the most recent version of formation or incorporation documents & term sheets',
    needHelpVisitSupport: 'If you need any help, or See how it Works Visit the help and support page',
    addingNewEquity: 'Adding new Equity',
    suggestedNames: 'Suggested Names',
    selectDate: 'select date',
    sharesAuthorized: 'Shares Authorized',
    numberOfSharesAssigned: 'number of shares assigned to this equity',
    preMoneyValuationOptional: 'Pre-Money Valuation (Optional)',
    preMoneyValuation: 'Pre-Money Valuation',
    commentOptional: 'Comment (Optional)',
    comment: 'Comment',
    back: 'Back',
    submit: 'Submit',
    addOption: 'Add Option',
    empowerYourEmployees: 'Empower your employees!',
    nextStepOptionPlanInfo:
      'In the next step, you will be able to create plans and pools In order to grant options to your employees, advisors, etc. We recommend preparing all the information regarding Options & ESOPs before clicking on the button.',
    options: 'Options',
    option: 'Option',
    equity: 'Equity',
    addNewPool: 'Add New Pool',
    poolName: 'Pool Name',
    assignNameToPool: 'assign a name to pool',
    addAuthorizedSharesForPlan: 'Add the Authorized share for this session',
    addConvertibleInstruments: 'Add Convertible Instruments',
    addNewConvertibleInstrument: 'Add a New Convertible Instrument',
    vestingSchedule: 'Vesting Schedule',
    setVestingSchedule:
      'Set a vesting schedule to give your employees full ownership rights to employer-provided assets over time...',
    addVestingSchedules: 'Add Vesting Schedules',
    vestingName: 'Vesting Name',
    duration: 'Duration',
    cliff: 'Cliff',
    upfrontVesting: 'Upfront Vesting',
    actions: 'Actions',
    edit: 'Edit',
    delete: 'Delete',
    vestingScheduleName: 'Vesting Schedule Name',
    vestingDurationMonths: 'Vesting Duration (Months)',
    vestingFrequencyMonths: 'Vesting Frequency (Months)',
    frequencyEndOfMonth: '*The frequency will occur at the end of the month',
    customAmountVestedAtCliff: 'Custom Amount Vested At Cliff',
    name: 'Name',
    simulateInvestmentRound: 'Simulate Investment Round',
    simulateExitScenario: 'Simulate Exit Scenario',
    investmentOverview: 'Personal Holdings',
    multiple: 'Multiple',
    multiples: 'Multiples',
    percentage: 'Percentage %',
    upfrontVestingAmount: 'Upfront Vesting Ammount',
    close: 'Close',
    prefix: 'Prefix',
    AddToDataroom: 'Add to Data Room',
    addNewShareholder: 'Add New Shareholder',
    manageUsers: 'Manage Shareholders',
    ownershipByShareholders: 'Ownership (FD) % By Shareholders',
    ownershipByShareClass: 'Ownership (FD) % By Share Class',
    authorizedSharedUsage: 'Authorized Shared Usage %',
    shareholder: 'Shareholder',
    shareholders: 'Shareholders',
    needHelpOrSeeHowItWorks: 'If you need any help, or See how it Works',
    visitHelpAndSupport: 'Visit the help and support page',
    equitySecurities: 'Equities',
    totalIssued: 'Total Issued',
    type: 'Type',
    issueShares: 'Issue Shares',
    equityType: 'Equity Type',
    equities: 'Equities',
    capitalCommited: 'Capital Commited',
    seniority: 'Seniority',
    conversionRatio: 'Conversion Ratio',
    className: 'Class Name',
    download: 'Download',
    detailedCaptable: 'Detailed Cap Table',
    grantName: 'Grant Name',
    vested: 'Vested',
    yes: 'Yes',
    no: 'No',
    remainingToVest: 'Remaining to Vest',
    exercisePrice: 'Exercise Price',
    totalCost: 'Total Cost',
    captableEquities: 'Cap table Equities',
    addEquities: 'Add Equities',
    equitySecuritiesDetail: 'Class Details',
    equityDetails: 'Equity Details',
    approvalDate: 'Approval Date',
    equityShareholdersOverview: 'Equity Shareholders Overview',
    myEquity: 'My Equity',
    sharesValue: 'Shares Value',
    myInvestments: 'My Investments',
    currentPricePerShare: 'Current Price per Share',
    sharesDetailsTable: 'Shares Details Table',
    mySharesDetails: 'My Shares Details',
    pricePerShare: 'Price per Share',
    issuanceDate: 'Issuance Date',
    optionsTable: 'Options Table',
    myOptionsDetails: 'My Options Details',
    frequency: 'Frequency',
    vestingPlansOverTime: 'Vesting Schedules',
    noChartData: 'No Chart Data',
    totalOptions: 'Total Options',
    currentVesting: 'Current Vesting',
    rate: 'Rate',
    youAreAboutToAddCompanyFoundersAndUsers: 'You are about to add your company founders and users',
    makeSureToAddAllCompanyUsers:
      "Make sure to add all your company's users, such as founders/investors/ESOPs holders, etc. to issue shares for them",
    addUsersAndFounders: 'Add Users & Founders',
    shareName: 'Share Name',
    sharePrice: 'Share Price',
    numberOfShares: 'Number of shares',
    totalValuation: 'Total Valuation',
    equityClass: 'Equity Class',
    participationCap: 'Participation Cap',
    dateOfIssuance: 'Date Of Issuance',
    sharesAvailableToIssue: 'shares are available to issue in this stock class',
    addNewVestingSchedule: 'Add New Vesting Schedule',
    vestingStartDate: 'Vesting Start Date',
    numberOfSharesIssued: 'Number Of Shares Issued',
    grantID: 'Grant ID',
    issueShare: 'Issue Share',
    issueSharesDescription: 'Issue shares for any shareholder in your company',
    share: 'Share',
    instrumentHolder: 'Instrument Holder',
    common: 'Common',
    preferred: 'Preferred',
    all: 'All',
    vestingDetails: 'Vesting Details',
    price: 'Price',
    totalVested: 'Total Vested',
    commentPlaceholder: 'leave any comment or note',
    sell: 'Sell',
    transfer: 'Transfer',
    sellShares: 'Sell Shares',
    numberOfSharesToSellLabel: 'Selecting the number of shares to sell',
    numberOfSharesToSellPlacholder: 'Select number of shares',
    transferShares: 'Transfer Shares',
    theReceiverUser: 'The receiver user',
    amountOfShares: 'Amount of shares',
    reason: 'Reason',
    reasonOfReject: 'Reason of Reject',
    reasonPlaceholderField: 'Please add a reason',
    shareDetails: 'Share Details',
    viewDetails: 'View Details',
    owner: 'Owner',
    status: 'Status',
    approve: 'Approve',
    reject: 'Reject',
    transactionSummary: 'Transaction Summary',
    transactionSummaryDisclaimer: 'As an owner we need a permission to complete this Transaction',
    shareOwnership: 'Share Ownership',
    email: 'Email',
    phoneNumber: 'Phone Number',
    transactionType: 'Transaction Type',
    rejectedText: 'You Rejected this Request',
    for_sale_share: 'Sell',
    share_transfer: 'Transfer',
    pending: 'Pending',
    approved: 'Approved',
    rejected: 'Rejected',
    canceled: 'Canceled',
    closed: 'Closed',
    askYourAdminForPermission: 'Ask your admin for permission',
    approvedText: 'Approved successfully',
    recipient: 'Recipient',
    recipientOwnership: 'Recipient Ownership',
    transactionRequests: 'Transaction Requests',
  },

  equity: {
    addingNewEquity: 'Adding new Equity',
    totalPersonalizedExperience:
      'Total Personalised Experience for your profile add your company info to get highest Value from Rasmal',
    equityType: 'Equity Type',
    common: 'Common',
    preferred: 'Preferred',
    equityNameLabel: 'Class Name',
    suggestedNames: 'Suggested Names',
    giveEquityNameLabel: 'Give the Equity name or label',
    boardApprovalDate: 'Board Approval Date',
    addBoardApprovalDate: 'Add the board approval date',
    sharesAuthorized: 'Shares Authorized',
    originalIssuePrice: 'Original Issue Price',
    preferences: 'Preferences',
    seniorityLevel: 'Seniority Level',
    conversionRatio: 'Conversion Ratio',
    multiple: 'Multiple',
    liquidationPreference: 'Liquidation Preference',
    participatingRights: 'Participating Rights',
    preMoneyValuation: 'Pre-Money Valuation',
    comment: 'Comment',
    commentPlaceholder: 'leave any comment or note',
    submit: 'Submit',
    back: 'Back',
    cancel: 'Cancel',
    participatingCapMultiple: 'Participating Cap Multiple',
    'Pre-seed Round': 'Pre-seed Round',
    'Seed Round': 'Seed Round',
    'Series A': 'Series A',
    'Series B': 'Series B',
    'Series C': 'Series C',
    'Series D': 'Series D',
  },

  options: {
    captable: 'Cap Table',
    options: 'Options',
    pools: 'Pools',
    addNewPool: 'Add New Pool',
    empowerYourEmployees: 'Empower your employees',
    emptyStateDescription:
      'In the next step, you will be able to create plans and pools In order to grant options to your employees, advisors, etc. We recommend preparing all the information regarding Options & ESOPs before clicking on the button.',
    poolName: 'Pool Name',
    ownershipFDPercentage: 'Ownership (FD) %',
    actions: 'Actions',
    grantOptions: 'Grant Options',
    optionsSecurities: 'Option Pools',
    optionsDetails: 'Options Details',
    boardApprovalDate: 'Board Approval Date',
    optionsOutstanding: 'Options Outstanding',
    authorizedShares: 'Authorized Shares',
    reservedOptions: 'Reserved Options',
    availableShares: 'Available Shares',
    availableOptions: 'Available Options',
    shareholder: 'Shareholder',
    prefix: 'Prefix',
    afterCreatingOptionsPlan:
      'After creating this options plan, you will be able to grant options to your employees, advisors, etc.',
    suggestedNames: 'Suggested Names',
    preMoneyValuation: 'Pre-Money Valuation',
    comment: 'Comment',
    commentPlaceholder: 'leave any comment or note',
    submit: 'Submit',
    back: 'Back',
    cancel: 'Cancel',
    sharesAuthorized: 'Shares Authorized',
    sharesAuthorizedPlaceholder: 'Add the number of shares reserved/authorized for the option class',
    selectDate: 'Select date',
    poolNamePlaceholder: 'assign a name to pool',
    ESOP: 'ESOP',
    'Advisory Pool': 'Advisory Pool',
    issueOption: 'Issue Option',
    vested: 'Vested',
    remainingToVest: 'Remaining to Vest',
    exercisePrice: 'Exercise Price',
    totalCost: 'Total Cost',
    vestingSchedule: 'Vesting Schedule',
    addNewShareholder: 'Add New Shareholder',
    grantName: 'Grant Name',
    years: 'years',
    optionsAvailableToGrant: 'Options available to grant',
    addNewVestingSchedule: 'Add New Vesting Schedule',
    setVestingSchedule:
      'Set a vesting schedule to give your employees full ownership rights to employer-provided assets over time',
    issuanceDate: 'Issuance Date',
    selectDateOfIssuance: 'Select the date of issuance',
    sharesIssued: 'Granted Options',

    createNewOption: 'Create New Option',
    securityName: 'Security Name',
    shareReserved: 'Share Reserved',
    companySecurityOption: 'Company / Security / Option',
    className: 'Class Name',
    sharesReserved: 'Shares Reserved',
    boardApprovalDateIsRequired: 'Board Approval Date Is Required',
    optionNameIsRequired: 'Option Name Is Required',
    equityIsRequired: 'Equity Is Required',
    totalGranted: 'Granted Options',
    underlyingSecurity: 'Class Name',
    optionGrantsOverview: 'Option Grants Overview',
    poolDetails: 'Pool Details',
    vestedPercentage: 'Vested Percentage',
    expirationPeriod: 'Expiration Period',
    vestingStartDate: 'Vesting Start Date',
    addOptionsAndESOPs: 'Add Options & ESOPs',
    vestingInfo: 'Vesting Info',
  },

  instruments: {
    convertibleInstruments: 'Convertible Instruments',
    captable: 'Cap Table',
    addConvertibleInstruments: 'Add Convertible Instruments',
    instrumentHolder: 'Instrument Holder',
    prefix: 'Prefix',
    principal: 'Principal',
    interest: 'Interest',
    valuationCap: 'Valuation Cap',
    discountPercentage: 'Discount (%)',
    issueDate: 'Issue Date',
    actions: 'Actions',
    instrumentName: 'Instrument Name',
    maturityDate: 'Maturity Date',
    earlyExitMultiple: 'Early Exit Multiple',
    conversionTriggerAmount: 'Conversion Trigger Amount',
    close: 'Close',
    commentPlaceholder: 'leave any comment or note',
    comment: 'Comment',
    investorName: 'Investor Name',
    addNewShareholder: 'Add New Shareholder',
    suggestedNames: 'Suggested Names',
    principalAmount: 'Principal Amount',
    instrumentNamePlaceholder: 'Select the investor to issue the convertible instrument',
    convertibleInstrumentName: 'Convertible Instrument Name',
    selectDateOfIssuance: 'Select the date of issuance',
    valuationCapPlaceholder: 'Add the maximum, valuation at which the instrument will convert to shares',
    discountPlaceholder:
      'Add the discount on the share price of the next round used when the instrument converts into shares',
    annualInterestRate: 'Annual Interest Rate (%)',
    annualInterestRatePlaceholder: 'Add the interest rate of the convertible instrument',
    selectDate: 'Slect date',
    conversionTriggerAmountPlaceholder:
      'Add the minimum fundraising amount at which the instrument will convert to shares',
    earlyExitMultiplePlaceholder:
      'Add the multiple given on the instruments purchase price in case an exit happened before the note converts into shares',
    SAFE: 'SAFE',
    KISS: 'KISS',
    'Convertible Note': 'Convertible Note',
    customizeConvertibleTerms:
      "Customize your Convertible Instrument's terms to any type: SAFE, KISS, or Note. *Some terms may not be included in your convertible instrument.",
    addNewConvertibleInstrument: 'Add a New Convertible Instrument',
    issueConvertibleNotes: 'Issue Convertible Notes, SAFE & KISS',
    recommendPrepareConvertibleDetails:
      'We recommend you prepare the Details of the Convertible Instrument such as Instrument Holder, Issue Date, etc to help you during the next step.',
    submit: 'Submit',
    back: 'Back',
    name: 'Name',
    convert: 'Convert',
    converted: 'Converted',
  },

  deleteModal: {
    deleteWarning: 'You are about to delete something important',
    deleteConfirmation: 'Are you certain about deleting the',
    impactCapTableWarning:
      'This action may impact your overall cap table, potentially causing damage and alterations to other equities.',
    delete: 'Delete',
    yes: 'Yes',
    cancel: 'Cancel',
  },

  history: {
    captable: 'Cap Table',
    transactionsHistory: 'Transactions History',
    entityID: 'Entity ID',
    actionType: 'Action Type',
    approvalDate: 'Approval Date',
    amount: 'Amount',
    transaction: 'Transaction',
    shareholderName: 'Shareholder Name',
    granted_options: 'Options Grant',
    convertible_instruments: 'Convertible Instrument Issuance',
    issue_share: 'Share Issuance',
    options: 'Pool Issuance',
  },
  myActivity: {
    myActivity: 'My Activity',
    personalHoldings: 'Personal Holdings',
    activitySummary: 'Activity Summary',
    numberOfTransactions: 'Number Of Transactions',
    totalSellShares: 'Total Sell Shares',
    totalTransferShares: 'Total Transfer Shares',
    totalPrice: 'Total Price',
    share: 'Share',
    shares: 'Shares',
    myTransactions: 'My Transactions',
    type: 'Type',
    status: 'Status',
    numberOfShares: 'Number Of Shares',
    total: 'Total',
    pricePerShare: 'Price/share',
    interested: 'Interested',
    actions: 'Actions',
    sell: 'Sell',
    transfer: 'Transfer',
    approved: 'Approved',
    pending: 'Pending',
    rejected: 'Rejected',
    cancel: 'Cancel',
    canceled: 'Canceled',
    open: 'Open',
    seeInterested: 'See Interested',
    new: 'New',
    delete: 'Delete',
    note: 'Note',
    for_sale_share: 'Sell',
    share_transfer: 'Transfer',
    interestedUsers: 'Interested Users',
    userInterested: 'User Interested',
    usersInterested: 'Users Interested',
    nameOfBuyer: 'Name of buyer',
    phoneNumber: 'Phone number',
    email: 'Email',
    reject: 'Reject',
    approve: 'Approve',
    close: 'Close',
    closed: 'Closed',
    requestPending: 'Request Pending',
    transferShares: 'Transfer Shares',
    finalStep: 'Final Step',
    beforeCompletingProcess: 'Before Completing this Process',
    ensureValueTransferred:
      'Make sure that the assumed value of the shares has been transferred before completing the process.',
    back: 'Back',
    confirm: 'Confirm',
  },
};
