import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { App } from 'antd';

import { queryClient } from '@/lib/react-query';
import { axiosClient } from '@/lib/axios';

type SharesPayload = {
  companyID?: number;
  forSaleShareID: number;
  interestID: number;
  body: {
    status: 'approved' | 'rejected' | 'closed';
  };
};

type SharesResponse = {
  message: string;
};

export const updateForSaleRequestStatus = async ({
  companyID,
  forSaleShareID,
  interestID,
  body,
}: SharesPayload): Promise<SharesResponse> => {
  const { data } = await axiosClient.put<SharesResponse>(
    `/companies/${companyID}/cap-table/equities/issued-shares/for-sale/${forSaleShareID}/interests/${interestID}`,
    body,
  );

  return data;
};
type UseUpdateForSaleRequestStatusOptions = {
  companyID: number;
  config?: Partial<UseMutationOptions<SharesResponse, AxiosError<{ message: string }>, SharesPayload>>;
  postSuccess?: () => void;
};

export const useUpdateForSaleRequestStatus = ({
  companyID,
  config,
  postSuccess,
}: UseUpdateForSaleRequestStatusOptions) => {
  const { notification: toaster } = App.useApp();
  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.message,
      });
    },
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({
        queryKey: ['requests-history', companyID],
      });
      await queryClient.invalidateQueries({
        queryKey: ['share-interests', companyID, params.forSaleShareID],
      });
      toaster.success({
        message: 'Updated Successfully',
        description: 'Request Status Updated Successfully',
      });
      postSuccess?.();
    },
    ...config,
    mutationFn: updateForSaleRequestStatus,
  });
};
