import { TFunction } from 'i18next';
import { ColumnsType } from 'antd/lib/table/InternalTable';

import { formatNumber } from '@/utils/formatNumber';
import withLoadingSkeleton from '@/HOCs/withLoadingSkeleton';
import { TransactionRequests } from '../types';
import { Flex, Tag, Typography } from 'antd';
import { StyledButton } from '@/lib/theme/components/Button';
import themeToken from '@lib/theme/tokens/index';

const { Text } = Typography;

type TransactionRequestsStatus = 'pending' | 'rejected' | 'approved' | 'canceled' | 'closed';

export const transactionRequestsColumns = (
  isLoading: boolean,
  t: TFunction<'captable', 'overview'>,
  onOpenReasonRejectOpened: () => void,
  onOpenTransactionSummary: () => void,
  setSelectedTransactionRequestId: (id: number) => void,
  onApprove: (id: number) => void,
): ColumnsType<TransactionRequests | Record<string, number>> => [
  {
    title: t('type'),
    dataIndex: 'type',
    render: withLoadingSkeleton(isLoading, (type: string) => {
      return <Text style={{ color: themeToken['branding-primary-6'] }}>{t(type)}</Text>;
    }),
  },
  {
    title: t('status'),
    dataIndex: 'status',
    render: withLoadingSkeleton(isLoading, (val: TransactionRequestsStatus) => {
      let color = '';
      switch (val) {
        case 'pending':
          color = 'orange';
          break;
        case 'rejected':
          color = 'red';
          break;
        case 'approved':
          color = 'purple';
          break;
        case 'canceled':
          color = 'blue';
          break;
        case 'closed':
          color = 'green';
          break;
        default:
      }
      return <Tag color={color}>{t(val)}</Tag>;
    }),
  },
  {
    title: t('owner'),
    dataIndex: 'from_user',
    render: withLoadingSkeleton(isLoading, (from_user: { name: string }) => {
      return from_user.name;
    }),
  },
  {
    title: t('recipient'),
    dataIndex: 'addressed_user',
    render: withLoadingSkeleton(isLoading, (addressed_user: { name: string }) => {
      return addressed_user.name;
    }),
  },
  {
    title: t('numberOfShares'),
    dataIndex: 'number_of_shares',
    render: withLoadingSkeleton(isLoading, (val: number) => formatNumber(val, { maximumFractionDigits: 4 })),
  },
  {
    title: t('total'),
    dataIndex: 'total_price',
    render: withLoadingSkeleton(isLoading, (val: number) => {
      return val;
    }),
  },
  {
    title: t('pricePerShare'),
    dataIndex: 'share_price',
    render: withLoadingSkeleton(isLoading, (val: number) => formatNumber(val, { maximumFractionDigits: 4 })),
  },
  {
    title: t('shareDetails'),
    width: '120px',
    render: withLoadingSkeleton(isLoading, (val: { id: number }) => {
      return (
        <StyledButton
          style={{
            padding: `${(themeToken.paddingXXS, themeToken.paddingXS)}`,
            color: themeToken.colorPrimary,
          }}
          type="link"
          size="small"
          onClick={() => {
            onOpenTransactionSummary();
            setSelectedTransactionRequestId(val.id);
          }}
        >
          {t('viewDetails')}
        </StyledButton>
      );
    }),
  },
  {
    title: t('actions'),
    width: '225px',
    render: withLoadingSkeleton(
      isLoading,
      (val: { id: number; status: TransactionRequestsStatus; my_reply: TransactionRequestsStatus }) => {
        if (val.status === 'pending') {
          if (val.my_reply === 'approved') {
            return <Text type="success">{t('approvedText')}</Text>;
          }
          return (
            <Flex gap={8}>
              <StyledButton type="primary" disabled={isLoading} onClick={() => onApprove(val.id)}>
                {t('approve')}
              </StyledButton>
              <StyledButton
                onClick={() => {
                  onOpenReasonRejectOpened();
                  setSelectedTransactionRequestId(val.id);
                }}
                danger
                type="default"
              >
                {t('reject')}
              </StyledButton>
            </Flex>
          );
        } else if (val.status === 'rejected' || val.my_reply === 'rejected') {
          return <Text type="danger">{t('rejectedText')}</Text>;
        } else {
          return <></>;
        }
      },
    ),
  },
];
