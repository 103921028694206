import CommonModal from '@/features/captable/components/Shared/CommonModal';
import { Divider, Flex, Form } from 'antd';
import { StyledButton } from '@/lib/theme/components/Button';
import themeToken from '@lib/theme/tokens/index';
import { StyledInput } from '@/lib/theme/components/Input';
import { useTranslation } from 'react-i18next';

interface ReasonRejectModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => void;
  loading?: boolean;
}

const ReasonRejectModal = ({ isOpen, onClose, onSubmit, loading }: ReasonRejectModalProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('captable', {
    keyPrefix: 'overview',
  });

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values: { reason: string }) => {
        onSubmit(values.reason);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <CommonModal isModalOpen={isOpen} handleCancel={onClose}>
      <h2 style={{ color: themeToken.primary_900, fontWeight: 500 }}>{t('reasonOfReject')}</h2>
      <Divider />
      <Form form={form} layout="vertical">
        <Form.Item
          name="reason"
          label={t('reason')}
          rules={[
            {
              required: true,
              message: 'Please enter reason',
            },
          ]}
        >
          <StyledInput placeholder={t('reasonPlaceholderField')} />
        </Form.Item>

        <Flex justify="space-between">
          <StyledButton ghost type="primary" onClick={onClose}>
            {t('back')}
          </StyledButton>
          <StyledButton type="primary" disabled={loading} onClick={handleSubmit}>
            {t('submit')}
          </StyledButton>
        </Flex>
      </Form>
    </CommonModal>
  );
};

export default ReasonRejectModal;
