import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { AxiosError } from 'axios';

import { axiosClient } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';

type UpdateTransactionRequestPayload = {
  companyID: number;
  requestID: number;
  payload: {
    status: string;
    rejection_reason?: string;
  };
};

export const updateTransactionRequestStatus = async ({
  companyID,
  requestID,
  payload,
}: UpdateTransactionRequestPayload): Promise<{ message: string }> => {
  const { data } = await axiosClient.put<{ message: string }>(
    `/companies/${companyID}/cap-table/requests/received/${requestID}`,
    payload,
  );

  return data;
};
type UseUpdateTransactionRequestOptions = {
  config?: Partial<
    UseMutationOptions<{ message: string }, AxiosError<{ message: string }>, UpdateTransactionRequestPayload>
  >;
  postSuccess?: () => void;
};

export const useUpdateTransactionRequest = ({ config, postSuccess }: UseUpdateTransactionRequestOptions) => {
  const { notification: toaster } = App.useApp();
  return useMutation({
    onError: (error) => {
      const errorResponse = (error.response?.data as { message: string }) || {};
      toaster.error({
        message: 'Something Went Wrong',
        description: errorResponse.message || error.message,
      });
    },
    onSuccess: async (_, params) => {
      const { companyID, requestID } = params;
      await queryClient.invalidateQueries({
        queryKey: ['transaction-requests'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['transaction-request-details', companyID, requestID],
      });
      toaster.success({
        message: 'Updated Successfully',
        description: 'Company Updated Successfully',
      });
      postSuccess?.();
    },
    ...config,
    mutationFn: updateTransactionRequestStatus,
  });
};
