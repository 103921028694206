import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Typography } from 'antd';

import { PageWrapper } from '@/components/Shared/Wrapper';
import CommonTable from '@/components/Shared/CommonTable';
import themeToken from '@lib/theme/tokens/index';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import EmptyWalletIcon from '@/components/Icons/EmptyWalletIcon';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import MainContainer from '@/components/Shared/MainContainer';

import { transactionsHistoryColumns } from '../utils/transactionsHistoryColumns';
import { useGetRequestsHistory } from '../api/getRequestsHistory';
import InterestedDetailsDrawer from '../components/myActivity/InterestedDetailsDrawer';
import { useUpdateSentRequestStatus } from '../api/updateSentRequestStatus';

const { Title } = Typography;

const MyActivity = () => {
  const { t } = useTranslation('captable', {
    keyPrefix: 'myActivity',
  });
  const { t: translation } = useTranslation('pageTitles');
  useDocumentTitle(translation('myActivity'));

  const { selectedCompany, fullCompanyData } = useSelectedCompanyStore((state) => state);

  const [paginationParams, setPaginationParams] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  });
  const { data: requestsHistoryData, isLoading: isRequestsHistoryLoading } = useGetRequestsHistory({
    companyID: selectedCompany?.cid as number,
    queryParams: {
      page: paginationParams.current,
      limit: paginationParams.pageSize,
    },
  });
  const { mutate: updateSentRequestStatus, isPending: isUpdateSentRequestStatusLoading } = useUpdateSentRequestStatus(
    {},
  );
  const [isInterestedDrawerOpen, setIsInterestedDrawerOpen] = useState(false);
  const [selectedShareID, setSelectedShareID] = useState<number>();
  const currencyCode = fullCompanyData?.company_data.company_currency.code ?? '';

  const handleDrawerOpen = (transactionID: number) => {
    setSelectedShareID(transactionID);
    setIsInterestedDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setIsInterestedDrawerOpen(false);
  };

  const handleUpdateSentRequestStatus = ({
    requestID,
    status,
  }: {
    requestID: number;
    status: 'canceled' | 'deleted';
  }) => {
    updateSentRequestStatus({
      companyID: selectedCompany?.cid,
      requestID,
      body: {
        status,
      },
    });
  };

  const isRequestsLoading = useMemo(
    () => isRequestsHistoryLoading || isUpdateSentRequestStatusLoading,
    [isUpdateSentRequestStatusLoading, isRequestsHistoryLoading],
  );

  const transactionsHistoryTableColumns = transactionsHistoryColumns(
    isRequestsLoading,
    t,
    currencyCode,
    handleDrawerOpen,
    handleUpdateSentRequestStatus,
  );

  useEffect(() => {
    if (requestsHistoryData?.metadata) {
      const { total } = requestsHistoryData.metadata;
      setPaginationParams((prev) => ({ ...prev, total }));
    }
  }, [requestsHistoryData?.metadata]);

  return (
    <MainContainer
      title={t('myActivity')}
      breadcrumbItems={[
        {
          href: '/company/investment-overview/my-investments',
          title: (
            <>
              <EmptyWalletIcon />
              {t('personalHoldings')}
            </>
          ),
        },
        {
          title: t('myActivity'),
        },
      ]}
    >
      <Flex gap={16} vertical>
        <PageWrapper gap={36}>
          <Flex gap={20} vertical>
            <Title
              level={5}
              style={{
                color: themeToken['branding-primary-6'],
                fontWeight: 600,
                marginBottom: themeToken.marginXXS,
              }}
            >
              {t('myTransactions')}
            </Title>
            <CommonTable
              columns={transactionsHistoryTableColumns}
              dataSource={requestsHistoryData?.data}
              isLoading={isRequestsLoading}
              paginationParams={paginationParams}
              setPaginationParams={setPaginationParams}
            />
          </Flex>
        </PageWrapper>
      </Flex>
      {selectedShareID && (
        <InterestedDetailsDrawer
          isOpen={isInterestedDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          selectedShareID={selectedShareID}
        />
      )}
    </MainContainer>
  );
};

export default MyActivity;
