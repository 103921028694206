import EmptyWalletIcon from '@/components/Icons/EmptyWalletIcon';
import CommonTable from '@/components/Shared/CommonTable';
import MainContainer from '@/components/Shared/MainContainer';
import { useTranslation } from 'react-i18next';
import { transactionRequestsColumns } from '../utils/transactionRequestsColumns';
import ReasonRejectModal from '../components/TransactionRequests/ReasonRejectModal';
import { useEffect, useState } from 'react';
import TransactionSummaryModal from '../components/TransactionRequests/TransactionSummaryModal';
import { useGetTransactionRequests } from '../api/getTransactionRequests';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { SortDirection } from '@/types';
import type { TransactionRequests } from '../types';
import { useUpdateTransactionRequest } from '../api/updateTransactionRequestStatus';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

function TransactionRequestsPage() {
  const { t } = useTranslation('captable', {
    keyPrefix: 'overview',
  });
  const { t: translation } = useTranslation('pageTitles');
  useDocumentTitle(translation('transactionRequests'));

  const { selectedCompany } = useSelectedCompanyStore((state) => state);

  const [isReasonRejectOpened, setIsReasonRejectOpened] = useState(false);
  const [isTransactionSummaryOpened, setIsTransactionSummaryOpened] = useState(false);
  const [selectedTransactionRequestId, setSelectedTransactionRequestId] = useState<number>();

  const { mutate, isPending } = useUpdateTransactionRequest({
    postSuccess: () => setIsReasonRejectOpened(false),
  });

  const onOpenReasonRejectOpened = () => {
    setIsReasonRejectOpened(true);
  };

  const onOpenTransactionSummary = () => {
    setIsTransactionSummaryOpened(true);
  };

  const onApprove = (requestId: number) => {
    mutate({
      companyID: selectedCompany?.cid as number,
      requestID: requestId,
      payload: { status: 'approved' },
    });
  };

  const onReject = (reason: string) => {
    mutate({
      companyID: selectedCompany?.cid as number,
      requestID: selectedTransactionRequestId as number,
      payload: { status: 'rejected', rejection_reason: reason },
    });
  };

  const onCloseReasonRejectOpened = () => {
    setIsReasonRejectOpened(false);
  };

  const onCloseTransactionSummary = () => {
    setIsTransactionSummaryOpened(false);
  };

  const [searchToken, setSearchToken] = useState<string>();
  const [sortParams, setSortParams] = useState<{
    sort_by: keyof TransactionRequests;
    sort_dir: SortDirection;
  }>();

  const [paginationParams, setPaginationParams] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  });

  const { data: transactionRequests, isLoading } = useGetTransactionRequests({
    companyID: selectedCompany?.cid as number,
    queryParams: {
      sort_by: sortParams?.sort_by,
      sort_dir: sortParams?.sort_dir,
      q: searchToken,
      page: paginationParams.current,
      limit: paginationParams.pageSize,
    },
  });

  const columns = transactionRequestsColumns(
    isLoading || isPending,
    t,
    onOpenReasonRejectOpened,
    onOpenTransactionSummary,
    setSelectedTransactionRequestId,
    onApprove,
  );

  useEffect(() => {
    if (transactionRequests?.metadata) {
      const { total } = transactionRequests.metadata;
      setPaginationParams((prev) => ({ ...prev, total }));
    }
  }, [transactionRequests?.metadata]);

  useEffect(() => {
    if (searchToken) setSearchToken(searchToken);
  }, [searchToken]);

  return (
    <MainContainer
      title={t('transactionRequests')}
      breadcrumbItems={[
        {
          href: '/company/investment-overview/my-investments',
          title: (
            <>
              <EmptyWalletIcon />
              {t('investmentOverview')}
            </>
          ),
        },
        {
          title: t('transactionRequests'),
        },
      ]}
    >
      <CommonTable
        columns={columns}
        dataSource={transactionRequests?.data}
        loading={isLoading}
        paginationParams={paginationParams}
        setPaginationParams={setPaginationParams}
        setSortParams={setSortParams}
      />
      <ReasonRejectModal
        isOpen={isReasonRejectOpened}
        onClose={onCloseReasonRejectOpened}
        onSubmit={onReject}
        loading={isPending}
      />
      {selectedTransactionRequestId && (
        <TransactionSummaryModal
          isOpen={isTransactionSummaryOpened}
          onClose={onCloseTransactionSummary}
          onApprove={() => onApprove(selectedTransactionRequestId)}
          onReject={onOpenReasonRejectOpened}
          transactionRequestId={selectedTransactionRequestId}
          loading={isPending}
        />
      )}
    </MainContainer>
  );
}

export default TransactionRequestsPage;
