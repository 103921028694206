import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import { PageWrapper } from '@/components/Shared/Wrapper';
import themeToken from '@lib/theme/tokens/index';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import MainContainer from '@/components/Shared/MainContainer';
import MarketPlaceIcon from '@/components/Icons/MarketPlaceIcon';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';

import ListItem from '../components/ListItem';
import { MarketPlaceList } from '../components/List';
import { useGetShareSales } from '../api/getShareSales';
import SellInformationModal from '../components/SellInformationModal';
import type { MarketPlaceItem } from '../types';

const { Text } = Typography;

const MarketPlace = () => {
  const { t } = useTranslation('marketPlace');
  const { t: translate } = useTranslation('pageTitles');
  useDocumentTitle(translate('marketPlace'));
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedShare, setSelectedShare] = useState<number>();

  const [paginationParams, setPaginationParams] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  });
  const { data: shareSalesData, isLoading: isShareSalesLoading } = useGetShareSales({
    companyID: selectedCompany?.cid,
    queryParams: {
      page: paginationParams.current,
      limit: paginationParams.pageSize,
    },
  });

  const handleModalCancel = () => setIsModalOpen(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleSelectShare = (shareID: number) => {
    setSelectedShare(shareID);
    handleModalOpen();
  };

  useEffect(() => {
    if (shareSalesData?.metadata) {
      const { total } = shareSalesData.metadata;
      setPaginationParams((prev) => ({ ...prev, total }));
    }
  }, [shareSalesData?.metadata]);

  return (
    <MainContainer
      title={t('marketPlace')}
      breadcrumbItems={[
        {
          title: (
            <>
              <MarketPlaceIcon width={12} height={20} />
              {t('marketPlace')}
            </>
          ),
        },
      ]}
    >
      <PageWrapper gap={16} vertical>
        <Text
          style={{
            color: themeToken['branding-primary-6'],
            fontWeight: 600,
            fontSize: themeToken.fontSizeLG,
          }}
        >
          {t('allMarketActivities')}
        </Text>
        <MarketPlaceList
          dataSource={shareSalesData?.data}
          renderItem={(item: MarketPlaceItem) => <ListItem item={item} handleSelectShare={handleSelectShare} />}
          loading={isShareSalesLoading}
        />
      </PageWrapper>
      {selectedShare && (
        <SellInformationModal
          isModalOpen={isModalOpen}
          handleModalCancel={handleModalCancel}
          selectedShare={selectedShare}
        />
      )}
    </MainContainer>
  );
};

export default MarketPlace;
