import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/lib/axios';
import type { Metadata, PaginationParams } from '@/types';

import { marketPlaceItems } from '../utils/marketPlaceitems';
import type { MarketPlaceItem } from '../types';

type SharesParams = {
  companyID?: number;
  queryParams?: PaginationParams;
};

type SharesResponse = {
  data: MarketPlaceItem[];
  metadata: Metadata;
};

export const getShareSales = async ({ companyID, queryParams }: SharesParams): Promise<SharesResponse> => {
  try {
    const { data } = await axiosClient.get<SharesResponse>(
      `/companies/${companyID}/cap-table/equities/issued-shares/for-sale`,
      { params: queryParams },
    );
    return data;
  } catch (error) {
    return {
      data: marketPlaceItems,
      metadata: {
        current: 1,
        total: 10,
        size: 10,
      },
    };
  }
};

type UseGetShareSales = {
  companyID?: number;
  queryParams?: SharesParams['queryParams'];
  config?: Partial<UseQueryOptions<SharesResponse>>;
};

export const useGetShareSales = ({ companyID, queryParams, config }: UseGetShareSales) => {
  return useQuery({
    queryKey: ['share-sales', companyID, queryParams],
    queryFn: () => getShareSales({ companyID, queryParams }),
    ...config,
    enabled: !!companyID,
  });
};
