import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/lib/axios';
import type { Metadata, PaginationParams } from '@/types';

import type { RequestsHistory } from '../types';

type SharesParams = {
  companyID?: number;
  queryParams?: PaginationParams;
};

type SharesResponse = {
  data: RequestsHistory[];
  metadata: Metadata;
};

export const getRequestsHistory = async ({ companyID, queryParams }: SharesParams): Promise<SharesResponse> => {
  const { data } = await axiosClient.get<SharesResponse>(`/companies/${companyID}/cap-table/requests/sent`, {
    params: queryParams,
  });

  return data;
};

type UseGetRequestsHistoryOptions = {
  companyID?: number;
  queryParams?: SharesParams['queryParams'];
  config?: Partial<UseQueryOptions<SharesResponse>>;
};

export const useGetRequestsHistory = ({ companyID, queryParams, config }: UseGetRequestsHistoryOptions) => {
  return useQuery({
    queryKey: ['requests-history', companyID, queryParams],
    queryFn: () => getRequestsHistory({ companyID, queryParams }),
    ...config,
    enabled: !!companyID,
  });
};
