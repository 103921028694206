import { QueryFunction, useQuery } from '@tanstack/react-query';

import axiosClient from '@/lib/axios';
import type { Metadata, PaginationParams } from '@/types';

import type { TransactionRequests } from '../types';

type TransactionRequestsParams = {
  companyID: number;
  queryParams?: {
    sort_by?: keyof TransactionRequests;
    sort_dir?: 'asc' | 'desc';
    q?: string;
  } & PaginationParams;
};

type TransactionRequestsResponse = {
  data: TransactionRequests[];
  metadata: Metadata;
};

export const getTransactionRequests = async ({
  companyID,
  queryParams,
}: TransactionRequestsParams): Promise<TransactionRequestsResponse> => {
  const { data } = await axiosClient.get<TransactionRequestsResponse>(
    `/companies/${companyID}/cap-table/requests/received`,
    {
      params: queryParams,
    },
  );

  return data;
};

type QueryFnType = typeof getTransactionRequests;

type UseGetTransactionRequestsOptions = {
  companyID: number;
  queryParams?: TransactionRequestsParams['queryParams'];
  config?: QueryFunction<QueryFnType>;
};

export const useGetTransactionRequests = ({ companyID, queryParams, config }: UseGetTransactionRequestsOptions) => {
  return useQuery({
    queryKey: ['transaction-requests', companyID, queryParams],
    queryFn: () => getTransactionRequests({ companyID, queryParams }),
    ...config,
  });
};
