import { RouteObject } from 'react-router-dom';

import MainLayout from '@/components/Layout/Layout';
import EmptyLayout from '@/components/Layout/EmptyLayout';

import { CaptableRoutes } from '@/features/captable/routes';
import { ScenarioModelingRoutes } from '@features/scenarioModeling';
import { ValuationRoutes } from '@/features/valuation';
import { UsersManagementRoutes } from '@/features/usersManagement/routes';
import { CompaniesRoutes } from '@/features/company/routes';
import { GoPremiumRoutes } from '@/features/premium/routes';
import { DataRoomsRoutes } from '@/features/dataRooms/routes';
import { UserRoutes } from '@/features/user/routes';
import { CompanyReviewRoutes } from '@/features/companyReview/routes';
import { ConsultationsRoutes } from '@/features/consultation/routes';
// import { DocumentGeneratorRoutes } from '@/features/documentGenerator/routes';
import { CompanyListingRoutes } from '@/features/companyListing/routes';
import { MarketPlaceRoutes } from '@/features/marketPlace/routes';

export const ProtectedRoutes: RouteObject[] = [
  {
    path: '/*',
    element: <MainLayout />,
    children: [
      {
        path: 'scenario-modeling/*',
        element: <ScenarioModelingRoutes />,
      },
      {
        path: 'captable/*',
        element: <CaptableRoutes />,
      },
      {
        path: 'valuation/*',
        element: <ValuationRoutes />,
      },
      {
        path: 'users-management/*',
        element: <UsersManagementRoutes />,
      },
      {
        path: 'company/*',
        element: <CompaniesRoutes />,
      },
      {
        path: 'data-rooms/*',
        element: <DataRoomsRoutes />,
      },
      {
        path: 'user/*',
        element: <UserRoutes />,
      },
      {
        path: 'go-premium/*',
        element: <GoPremiumRoutes />,
      },
      {
        path: 'company-review/*',
        element: <CompanyReviewRoutes />,
      },
      // {
      //   path: 'document-generator/*',
      //   element: <DocumentGeneratorRoutes />,
      // },
      {
        path: 'market-place/*',
        element: <MarketPlaceRoutes />,
      },
      {
        path: 'addon/*',
        element: <ConsultationsRoutes />,
      },
    ],
  },
  {
    path: '/*',
    element: <EmptyLayout />,
    children: [
      {
        path: 'company-listing/*',
        element: <CompanyListingRoutes />,
      },
    ],
  },
];
