import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Flex, Typography, Grid } from 'antd';

import { getNameInitials } from '@/utils/getNameInitials';
import { StyledButton } from '@/lib/theme/components/Button';
import themeToken from '@lib/theme/tokens/index';

import type { ShareInterest } from '../../types';

const { Text } = Typography;
const { useBreakpoint } = Grid;

type Props = {
  shareInterest: ShareInterest;
  handleOpenFinalStepModal: (interestID: number) => void;
  isUpdateForSaleRequestStatusLoading: boolean;
  handleUpdateForSaleRequestStatus: ({
    requestID,
    status,
  }: {
    requestID: number;
    status: 'approved' | 'rejected';
  }) => void;
};

const InterestedUserItem = ({
  shareInterest,
  handleOpenFinalStepModal,
  isUpdateForSaleRequestStatusLoading,
  handleUpdateForSaleRequestStatus,
}: Props) => {
  const { t } = useTranslation('captable', {
    keyPrefix: 'myActivity',
  });
  const screens = useBreakpoint();

  const actionButtons = useMemo(() => {
    if (shareInterest.status === 'pending') {
      return (
        <StyledButton
          size="large"
          type="primary"
          disabled
          style={{
            color: themeToken.white,
            background: themeToken.colorWarningText,
          }}
          block={!screens.sm}
        >
          {t('requestPending')}
        </StyledButton>
      );
    } else if (shareInterest.status === 'approved') {
      return (
        <StyledButton
          type="primary"
          size="large"
          onClick={() => handleOpenFinalStepModal(shareInterest.id)}
          block={!screens.sm}
        >
          {t('transferShares')}
        </StyledButton>
      );
    } else {
      return (
        <>
          <StyledButton
            danger
            size="large"
            disabled={!!shareInterest.status || isUpdateForSaleRequestStatusLoading}
            onClick={() => {
              handleUpdateForSaleRequestStatus({
                requestID: shareInterest.id,
                status: 'rejected',
              });
            }}
            block={!screens.sm}
          >
            {t('reject')}
          </StyledButton>
          <StyledButton
            type="primary"
            size="large"
            disabled={!!shareInterest.status || isUpdateForSaleRequestStatusLoading}
            onClick={() => {
              handleUpdateForSaleRequestStatus({
                requestID: shareInterest.id,
                status: 'approved',
              });
            }}
            block={!screens.sm}
          >
            {t('approve')}
          </StyledButton>
        </>
      );
    }
  }, [
    shareInterest.status,
    shareInterest.id,
    handleOpenFinalStepModal,
    isUpdateForSaleRequestStatusLoading,
    handleUpdateForSaleRequestStatus,
    screens.sm,
    t,
  ]);

  return (
    <Flex
      vertical
      style={{
        paddingBlockEnd: '12px',
        borderBottom: `1px solid ${themeToken['branding-natural-3']}`,
      }}
    >
      <Flex gap={8} align="center" wrap={!screens.sm ? 'wrap' : 'nowrap'}>
        <Avatar
          shape="square"
          size={32}
          style={{
            backgroundColor: themeToken.colorPrimary,
            borderRadius: 1,
            alignSelf: 'self-start',
            marginTop: '5px',
          }}
        >
          {getNameInitials(shareInterest.company_user.name)}
        </Avatar>
        <Flex gap={8} vertical>
          <Flex vertical>
            <Text
              style={{
                color: themeToken['table-color-text'],
                fontSize: themeToken.fontSizeLG,
                fontWeight: 600,
              }}
            >
              {shareInterest.company_user.name}
            </Text>
            <Text
              style={{
                color: themeToken['branding-natural-6'],
                fontSize: themeToken.fontSizeXSM,
                fontWeight: 600,
              }}
            >
              {t('nameOfBuyer')}
            </Text>
          </Flex>
          <Flex align="center" gap={16}>
            <Flex vertical>
              <Text
                style={{
                  color: themeToken['table-color-text'],
                  fontSize: themeToken.fontSizeLG,
                  fontWeight: 600,
                }}
              >
                {shareInterest.company_user.mobile}
              </Text>
              <Text
                style={{
                  color: themeToken['branding-natural-6'],
                  fontSize: themeToken.fontSizeSM,
                  fontWeight: 600,
                }}
              >
                {t('phoneNumber')}
              </Text>
            </Flex>
            <Flex vertical>
              <Text
                style={{
                  color: themeToken['table-color-text'],
                  fontSize: themeToken.fontSizeLG,
                  fontWeight: 600,
                }}
              >
                {shareInterest.company_user.mail}
              </Text>
              <Text
                style={{
                  color: themeToken['branding-natural-6'],
                  fontSize: themeToken.fontSizeSM,
                  fontWeight: 600,
                }}
              >
                {t('email')}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          align="center"
          gap={16}
          style={{
            marginInlineStart: 'auto',
            width: !screens.sm ? '100%' : '',
          }}
        >
          {actionButtons}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InterestedUserItem;
