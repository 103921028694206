import { Flex, Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';

const { Text } = Typography;

type Props = {
  title: string;
  value: string;
};

const ItemInfo = ({ title, value }: Props) => {
  return (
    <Flex gap={2} vertical>
      <Text
        style={{
          color: themeToken['branding-natural-6'],
          fontSize: themeToken.fontSizeSM,
          fontWeight: 600,
        }}
      >
        {title}
      </Text>
      <Text
        style={{
          color: themeToken.colorPrimary,
          fontSize: themeToken.fontSizeXL,
          fontWeight: 600,
        }}
      >
        {value}
      </Text>
    </Flex>
  );
};

export default ItemInfo;
