import { Flex } from 'antd';
import styled from 'styled-components';

import themeToken from '@lib/theme/tokens/index';

export const MarketPlaceInfoContainer = styled(Flex)`
  &.ant-flex {
    border: 1px solid ${themeToken['branding-natural-1']};
    border-radius: ${themeToken.borderRadius}px;
    & > .ant-flex {
      padding: ${themeToken.paddingXS}px 10px;
    }
  }
`;
