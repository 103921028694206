export const marketPlaceItems = Array(4)
  .fill(1)
  .map((_, i) => ({
    id: i + 1,
    number_of_shares: 800,
    share_price: 10,
    total_price: 800 * 10,
    created_at: new Date(),
    company_user: {
      name: `Mark Jaycob ${i + 1}`,
    },
    my_interest_status: i % 2 === 0 ? 'approved' : null,
  }));

export const marketPlaceItemsDetails = Array(4)
  .fill(1)
  .map((_, i) => ({
    id: i + 1,
    number_of_shares: 800,
    share_price: 10,
    total_price: 800 * 10,
    created_at: new Date(),
    company_user: {
      name: `Mark Jaycob ${i + 1}`,
      mobile: '05969692423',
      mail: 'email@gmail.com',
    },
    equity_class: 'Seed Round',
  }));
