import { Avatar, Flex, Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';
import { getNameInitials } from '@/utils/getNameInitials';

const { Text } = Typography;

interface TransitionerInfoProps {
  name: string;
  children: React.ReactNode;
}

const TransitionerInfo = ({ name, children }: TransitionerInfoProps) => {
  return (
    <Flex gap={8} align="center">
      <Avatar
        shape="square"
        size={40}
        style={{
          backgroundColor: themeToken.colorPrimary,
          borderRadius: 1,
        }}
      >
        {getNameInitials(name)}
      </Avatar>
      <Flex vertical gap={0}>
        <Text style={{ color: 'black', fontWeight: 700 }}>{name}</Text>
        {children}
      </Flex>
    </Flex>
  );
};

export default TransitionerInfo;
