import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/lib/axios';
import type { Metadata, PaginationParams } from '@/types';

import type { ShareInterest } from '../types';

type SharesParams = {
  companyID?: number;
  shareID: number;
  queryParams?: PaginationParams;
};

type SharesResponse = {
  data: ShareInterest[];
  metadata: Metadata;
};

export const getShareInterests = async ({ companyID, shareID, queryParams }: SharesParams): Promise<SharesResponse> => {
  const { data } = await axiosClient.get<SharesResponse>(
    `companies/${companyID}/cap-table/equities/issued-shares/for-sale/${shareID}/interests`,
    {
      params: queryParams,
    },
  );

  return data;
};

type UseGetShareInterestsOptions = {
  companyID?: number;
  shareID: number;
  queryParams?: SharesParams['queryParams'];
  config?: Partial<UseQueryOptions<SharesResponse>>;
};

export const useGetShareInterests = ({ companyID, shareID, queryParams, config }: UseGetShareInterestsOptions) => {
  return useQuery({
    queryKey: ['share-interests', companyID, shareID, queryParams],
    queryFn: () => getShareInterests({ companyID, shareID, queryParams }),
    ...config,
    enabled: !!companyID && !!shareID,
  });
};
