import { Divider, Flex, Typography } from 'antd';
import { StyledButton } from '@/lib/theme/components/Button';
import themeToken from '@lib/theme/tokens/index';
import styled from 'styled-components';
import { StyledSharednModal } from '@/components/Shared/StyledSharedModal';
import TransitionerInfo from '@/components/Shared/TransitionerInfo';
import { useTranslation } from 'react-i18next';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { useGetTransactionRequestDetails } from '../../api/getTransactionRequestDetails';
import dayjs from 'dayjs';

const { Text } = Typography;

interface TransactionSummaryModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApprove: () => void;
  onReject: () => void;
  transactionRequestId: number;
  loading?: boolean;
}

const StyledText = styled(Text)`
  margin: 5px 0;
`;

const StyledTextLabel = styled(Text)`
  color: ${themeToken['branding-primary-6']};
  font-weight: 500;
  font-size: 14px;
`;

const StyledTextValue = styled(Text)`
  color: ${themeToken['branding-primary-6']};
  font-weight: 650;
  font-size: 14px;
`;

const CustomStyledSharedModal = styled(StyledSharednModal)`
  .ant-modal-body {
    padding: 0;
  }
`;

const TransactionSummaryModal = ({
  isOpen,
  onClose,
  onApprove,
  onReject,
  transactionRequestId,
  loading,
}: TransactionSummaryModalProps) => {
  const { t } = useTranslation('captable', {
    keyPrefix: 'overview',
  });

  const selectedCompany = useSelectedCompanyStore((state) => state.selectedCompany);

  const { data: transactionRequestDetailsData } = useGetTransactionRequestDetails({
    companyID: selectedCompany?.cid as number,
    transctionRequestId: transactionRequestId,
  });

  const actionButtons = () => {
    if (transactionRequestDetailsData?.status === 'pending') {
      if (transactionRequestDetailsData?.my_reply === 'approved') {
        return (
          <Flex justify="end">
            <Text type="success" style={{ marginTop: '3%' }}>
              {t('approvedText')}
            </Text>
          </Flex>
        );
      }
      return (
        <Flex gap={8} justify="end" style={{ marginTop: '3%' }}>
          <StyledButton danger onClick={onReject}>
            {t('reject')}
          </StyledButton>
          <StyledButton type="primary" disabled={loading} onClick={onApprove}>
            {t('approve')}
          </StyledButton>
        </Flex>
      );
    } else if (
      transactionRequestDetailsData?.status === 'rejected' ||
      transactionRequestDetailsData?.my_reply === 'rejected'
    ) {
      return (
        <Flex justify="end">
          <Text type="danger" style={{ marginTop: '3%' }}>
            {t('rejectedText')}
          </Text>
        </Flex>
      );
    } else {
      return <></>;
    }
  };

  return (
    <CustomStyledSharedModal zIndex={20} footer={null} open={isOpen} onCancel={onClose}>
      <h2 style={{ color: themeToken['branding-primary-6'], fontWeight: 700 }}>{t('transactionSummary')}</h2>
      <Divider style={{ margin: '10px 0' }} />
      <StyledText style={{ color: themeToken['branding-primary-6'], fontWeight: 700 }}>
        {t('transactionSummaryDisclaimer')}
      </StyledText>

      <Flex
        style={{
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: themeToken['branding-natural-1'],
          borderRadius: '6px',
          padding: '16px',
          marginTop: '3%',
        }}
        justify="space-between"
      >
        <div>
          <TransitionerInfo name={transactionRequestDetailsData?.from_user.name ?? ''}>
            <Text style={{ color: themeToken['branding-natural-6'], fontWeight: 600, fontSize: '10px' }}>
              {t('shareOwnership')}
            </Text>
          </TransitionerInfo>
        </div>
        <Flex vertical>
          <StyledTextValue>{transactionRequestDetailsData?.from_user.mail}</StyledTextValue>
          <StyledTextValue>{transactionRequestDetailsData?.from_user.phone}</StyledTextValue>
        </Flex>
      </Flex>
      <Flex
        style={{
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: themeToken['branding-natural-1'],
          borderRadius: '6px',
          padding: '16px',
          marginTop: '3%',
        }}
        justify="space-between"
      >
        <div>
          <TransitionerInfo name={transactionRequestDetailsData?.addressed_user.name ?? ''}>
            <Text style={{ color: themeToken['branding-natural-6'], fontWeight: 600, fontSize: '10px' }}>
              {t('recipientOwnership')}
            </Text>
          </TransitionerInfo>
        </div>
        <Flex vertical>
          <StyledTextValue>{transactionRequestDetailsData?.addressed_user.mail}</StyledTextValue>
          <StyledTextValue>{transactionRequestDetailsData?.addressed_user.phone}</StyledTextValue>
        </Flex>
      </Flex>
      <Flex
        vertical
        style={{
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: themeToken['branding-natural-1'],
          borderRadius: '15px',
          marginTop: '3%',
        }}
      >
        <div style={{ padding: '16px' }}>
          <Flex justify="space-between">
            <StyledTextLabel>{t('issuanceDate')}</StyledTextLabel>
            <StyledTextValue>{dayjs(transactionRequestDetailsData?.created_at).format('YYYY-MM-DD')}</StyledTextValue>
          </Flex>
          <Flex justify="space-between">
            <StyledTextLabel>{t('equityClass')}</StyledTextLabel>
            <StyledTextValue>{transactionRequestDetailsData?.equity_class}</StyledTextValue>
          </Flex>
          <Flex justify="space-between">
            <StyledTextLabel>{t('grantID')}</StyledTextLabel>
            <StyledTextValue>{transactionRequestDetailsData?.grant_id}</StyledTextValue>
          </Flex>
          <Flex justify="space-between">
            <StyledTextLabel>{t('numberOfShares')}</StyledTextLabel>
            <StyledTextValue>{transactionRequestDetailsData?.number_of_shares}</StyledTextValue>
          </Flex>
          <Flex justify="space-between">
            <StyledTextLabel>{t('pricePerShare')}</StyledTextLabel>
            <StyledTextValue>{transactionRequestDetailsData?.share_price}</StyledTextValue>
          </Flex>
          <Flex justify="space-between">
            <StyledTextLabel>{t('transactionType')}</StyledTextLabel>
            <StyledTextValue>{t(transactionRequestDetailsData?.type || '')}</StyledTextValue>
          </Flex>
        </div>
        <Flex
          align="center"
          style={{ background: themeToken['branding-primary-1'], padding: ' 5px 16px', textAlign: 'center' }}
          justify="space-between"
        >
          <StyledTextLabel>{t('total')}</StyledTextLabel>
          <StyledTextValue>{transactionRequestDetailsData?.total_price}</StyledTextValue>
        </Flex>
      </Flex>
      {actionButtons()}
    </CustomStyledSharedModal>
  );
};

export default TransactionSummaryModal;
