import { useTranslation } from 'react-i18next';
import { Divider, Flex, Typography } from 'antd';

import { StyledButton } from '@/lib/theme/components/Button';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import CommonModal from '@/features/captable/components/Shared/CommonModal';
import NoAccessIcon from '@/components/Icons/NoAccessIcon';
import themeToken from '@lib/theme/tokens/index';

import { useUpdateForSaleRequestStatus } from '../../api/updateForSaleRequestStatus';

const { Title, Text } = Typography;

type Props = {
  isOpen: boolean;
  forSaleShareID: number;
  selectedInterestID: number;
  handleCancel: () => void;
};

const FinalStepModal = ({ isOpen, handleCancel, forSaleShareID, selectedInterestID }: Props) => {
  const { t } = useTranslation('captable', {
    keyPrefix: 'myActivity',
  });
  const { selectedCompany } = useSelectedCompanyStore((state) => state);

  const { mutate: updateForSaleRequestStatus, isPending: isUpdateForSaleRequestStatusLoading } =
    useUpdateForSaleRequestStatus({
      companyID: selectedCompany?.cid as number,
      postSuccess: handleCancel,
    });

  const handleUpdateForSaleRequestStatus = (status: 'closed') => {
    updateForSaleRequestStatus({
      companyID: selectedCompany?.cid,
      forSaleShareID,
      interestID: selectedInterestID,
      body: {
        status,
      },
    });
  };

  return (
    <CommonModal isModalOpen={isOpen} handleCancel={handleCancel}>
      <Title
        style={{
          color: themeToken['branding-primary-6'],
          fontWeight: 600,
          fontSize: themeToken.fontSizeLG,
        }}
      >
        {t('finalStep')}
      </Title>

      <Divider
        style={{
          marginBlock: themeToken.margin,
          borderColor: themeToken['branding-natural-3'],
        }}
      />
      <Flex justify="center" align="center" gap={8} vertical>
        <NoAccessIcon />
        <Title
          style={{
            color: themeToken['branding-primary-6'],
            fontSize: themeToken.fontSizeHeading4,
          }}
        >
          {t('beforeCompletingProcess')}
        </Title>
        <Text
          style={{
            color: themeToken['branding-natural-9'],
            fontSize: themeToken.fontSizeHeading6,
            fontWeight: themeToken.fontWeightStrong,
          }}
        >
          {t('ensureValueTransferred')}
        </Text>
        <Flex
          align="center"
          gap={12}
          style={{
            marginTop: themeToken.marginXS,
          }}
        >
          <StyledButton onClick={handleCancel}>{t('back')}</StyledButton>
          <StyledButton
            type="primary"
            onClick={() => handleUpdateForSaleRequestStatus('closed')}
            disabled={isUpdateForSaleRequestStatusLoading}
          >
            {t('confirm')}
          </StyledButton>
        </Flex>
      </Flex>
    </CommonModal>
  );
};

export default FinalStepModal;
