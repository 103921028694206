import { QueryFunction, useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/lib/axios';

import type { ValuationHistoryData } from '../types';

type CompanyPayload = {
  companyID: number;
};

type CompanyResponse = {
  data: ValuationHistoryData;
};

export const getValuationHistory = async ({ companyID }: CompanyPayload): Promise<ValuationHistoryData> => {
  const { data } = await axiosClient.get<CompanyResponse>(`/companies/${companyID}/dashboard/valuation-history`);

  return data?.data;
};
type QueryFnType = typeof getValuationHistory;

type UseGetValuationHistoryOptions = {
  companyID: number;
  config?: QueryFunction<QueryFnType>;
};

export const useGetValuationHistory = ({ companyID, config }: UseGetValuationHistoryOptions) => {
  return useQuery({
    queryKey: ['valuation-history', companyID],
    queryFn: () => getValuationHistory({ companyID }),
    ...config,
  });
};
