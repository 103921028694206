const MarketPlaceIcon = ({
  active,
  width = 18,
  height = 18,
  stroke = '#434750',
}: {
  active?: boolean;
  width?: number;
  height?: number;
  stroke?: string;
}) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.25757 8.41504V11.7825C2.25757 15.15 3.60757 16.5 6.97507 16.5H11.0176C14.3851 16.5 15.7351 15.15 15.7351 11.7825V8.41504"
      stroke={active ? '#6430DA' : stroke}
      strokeWidth="1.35"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99987 9C10.3724 9 11.3849 7.8825 11.2499 6.51L10.7549 1.5H7.25237L6.74987 6.51C6.61487 7.8825 7.62737 9 8.99987 9Z"
      stroke={stroke}
      fill={active ? '#6430DA4A' : 'none'}
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7326 9C15.2476 9 16.3576 7.77 16.2076 6.2625L15.9976 4.2C15.7276 2.25 14.9776 1.5 13.0126 1.5H10.7251L11.2501 6.7575C11.3776 7.995 12.4951 9 13.7326 9Z"
      stroke={active ? '#6430DA' : stroke}
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.22979 9C5.46729 9 6.58479 7.995 6.70479 6.7575L6.86979 5.1L7.22979 1.5H4.94229C2.97729 1.5 2.22729 2.25 1.95729 4.2L1.75479 6.2625C1.60479 7.77 2.71479 9 4.22979 9Z"
      stroke={active ? '#6430DA' : stroke}
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 12.75C7.7475 12.75 7.125 13.3725 7.125 14.625V16.5H10.875V14.625C10.875 13.3725 10.2525 12.75 9 12.75Z"
      stroke={active ? '#6430DA' : stroke}
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MarketPlaceIcon;
