import styled from 'styled-components';
import { useEffect } from 'react';
import { Flex } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import ListingBackground from '@/assets/company-listing/listing-bg.png';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';

const CompanyListingLayout = styled(Flex)`
  background-image: url(${ListingBackground});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
`;

const RouteWrapper = () => {
  const { fullCompanyData, selectedCompany } = useSelectedCompanyStore((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    const companyListingFeature = fullCompanyData?.current_plan.features.filter(({ key }) => key === 'company.forsale');

    if (companyListingFeature?.length) {
      const [{ premium, included }] = companyListingFeature;
      if (!(premium && included)) {
        navigate(selectedCompany?.is_owner ? '/company/dashboard' : '/company/investment-overview/my-investments');
      }
      if (fullCompanyData?.company_data.is_listed) {
        navigate('/company-listing/thank-you');
      }
    }
  }, [fullCompanyData]);

  return (
    <CompanyListingLayout vertical align="center" justify="center">
      <Outlet />
    </CompanyListingLayout>
  );
};

export default RouteWrapper;
