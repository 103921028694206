import React, { useEffect } from 'react';
import { StyledSelect } from '@/lib/theme/components/Select';
import { Metadata } from '@/types';
import { Form } from 'antd/lib';

type CommonDropDownFieldProps<T> = {
  data: T[] | undefined;
  metadata: Metadata | undefined;
  placeholder: string;
  onChange?: (value: string | number) => void;
  isLoading: boolean;
  optionsListPage: number;
  setOptionsListPage: (page: number) => void;
  optionsHasNextPage: boolean;
  labelKey: string;
  setOptionsHasNextPage: (hasNextPage: boolean) => void;
  setOptionsSearchQuery: (query: string) => void;
  formItemName?: string;
};

type RecordType = { [key: string]: string | number; id: string | number };

function CommonDropDownField<T>({
  data,
  metadata,
  placeholder,
  onChange,
  isLoading,
  optionsListPage,
  setOptionsListPage,
  optionsHasNextPage,
  labelKey,
  setOptionsHasNextPage,
  setOptionsSearchQuery,
  formItemName,
}: CommonDropDownFieldProps<T>) {
  const [options, setOptions] = React.useState<{ label: string; value: number }[]>([]);

  const onOptionsScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.currentTarget;
    if (optionsHasNextPage && !isLoading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setOptionsListPage(optionsListPage + 1);
      target.scrollTo(0, target.scrollHeight);
    }
  };

  const onOptionsSearch = (value: string) => {
    setOptionsSearchQuery(value);
    setOptionsListPage(1);
    setOptions([]);
  };

  useEffect(() => {
    if (!metadata) return;

    const totalRecords = metadata.total ?? 0;
    const pageSize = metadata.size ?? 10;
    const hasNextPage = Math.ceil(totalRecords / pageSize) > optionsListPage;
    setOptionsHasNextPage(hasNextPage);

    setOptions((prev) => {
      const _options = [...prev];
      const records: RecordType[] = (data as RecordType[]) || [];

      const newRecords = records.map((record: RecordType) => {
        return { label: String(record[labelKey]), value: Number(record.id) };
      });

      newRecords.forEach((rec: { label: string; value: number }) => {
        if (!_options.some((item) => item.value === rec.value)) _options.push(rec);
      });
      return _options;
    });
  }, [data, optionsListPage, labelKey, setOptionsHasNextPage, metadata]);

  const optionsFilterOption: (input: string, option?: { label: string; value: number }) => boolean = (
    input,
    option,
  ) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form.Item name={formItemName}>
      <StyledSelect
        showSearch
        loading={isLoading}
        onPopupScroll={onOptionsScroll}
        options={options}
        onSearch={onOptionsSearch}
        filterOption={optionsFilterOption}
        placeholder={placeholder}
        onChange={onChange}
      />
    </Form.Item>
  );
}

export default CommonDropDownField;
