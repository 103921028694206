import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { App } from 'antd';

import { queryClient } from '@/lib/react-query';
import { axiosClient } from '@/lib/axios';

type SharesParams = {
  companyID?: number;
  saleShareID: number;
};

type SharesResponse = {
  message: string;
};

export const expressInterest = async ({ companyID, saleShareID }: SharesParams): Promise<SharesResponse> => {
  try {
    const { data } = await axiosClient.post<SharesResponse>(
      `companies/${companyID}/cap-table/equities/issued-shares/for-sale/${saleShareID}/interests`,
    );
    return data;
  } catch (error) {
    return { message: 'success' };
  }
};
type UseExpressInterestOptions = {
  companyID: number;
  saleShareID: number;
  config?: Partial<UseMutationOptions<SharesResponse, AxiosError<{ message: string }>, SharesParams>>;
  postSuccess?: () => void;
};

export const useExpressInterest = ({ companyID, saleShareID, config, postSuccess }: UseExpressInterestOptions) => {
  const { notification: toaster } = App.useApp();
  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.message,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['share-sales', companyID],
      });
      await queryClient.invalidateQueries({
        queryKey: ['share-sale', companyID, saleShareID],
      });
      toaster.success({
        message: 'Interested Successfully',
        description: 'Interest Sent Successfully',
      });
      postSuccess?.();
    },
    ...config,
    mutationFn: expressInterest,
  });
};
