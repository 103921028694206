import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import AuthorizedRoute from '@/components/AuthorizedRoute';
import ExpiryChecker from '@/components/ExpiryChecker';
import FallbackSpin from '@/components/Shared/FallbackSpin';

import MarketPlace from '../views/MarketPlace';

export const MarketPlaceRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        {/* //TODO: update permission once implemented */}
        <Route element={<AuthorizedRoute allowedPermissions={['company.my-investment']} />}>
          <Route element={<ExpiryChecker />}>
            <Route index element={<MarketPlace />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};
