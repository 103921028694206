import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Flex, Skeleton, Typography } from 'antd';

import { StyledDrawer } from '@/features/captable/components/Shared/StyledDrawer';
import { StyledButton } from '@/lib/theme/components/Button';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { StyledPagination } from '@/features/dataRooms/components/StyledPagination';
import themeToken from '@lib/theme/tokens/index';

import InterestedUserItem from './InterestedUserItem';
import { useGetShareInterests } from '../../api/getShareInterests';
import FinalStepModal from './FinalStepModal';
import { useUpdateForSaleRequestStatus } from '../../api/updateForSaleRequestStatus';

const { Text } = Typography;

type Props = {
  isOpen: boolean;
  selectedShareID: number;
  handleDrawerClose: () => void;
};

const InterestedDetailsDrawer = ({ isOpen, selectedShareID, handleDrawerClose }: Props) => {
  const { t } = useTranslation('captable', {
    keyPrefix: 'myActivity',
  });
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const [isFinalStepModalOpen, setIsFinalStepModalOpen] = useState(false);
  const [selectedInterestID, setSelectedInterestID] = useState<number>();
  const [paginationParams, setPaginationParams] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  });

  const { data: shareInterestsData, isLoading: isShareInterestsLoading } = useGetShareInterests({
    companyID: selectedCompany?.cid as number,
    shareID: selectedShareID,
    queryParams: {
      page: paginationParams.current,
      limit: paginationParams.pageSize,
    },
  });

  const { mutate: updateForSaleRequestStatus, isPending: isUpdateForSaleRequestStatusLoading } =
    useUpdateForSaleRequestStatus({
      companyID: selectedCompany?.cid as number,
    });

  const handleModalOpen = (interestID: number) => {
    setSelectedInterestID(interestID);
    setIsFinalStepModalOpen(true);
  };
  const handleModalClose = () => {
    setIsFinalStepModalOpen(false);
  };

  const onPaginationChange = (page: number, pageSize: number) => {
    setPaginationParams((prev) => ({ ...prev, current: page, pageSize }));
  };

  const handleUpdateForSaleRequestStatus = useCallback(
    ({ requestID, status }: { requestID: number; status: 'approved' | 'rejected' }) => {
      updateForSaleRequestStatus({
        companyID: selectedCompany?.cid,
        forSaleShareID: selectedShareID,
        interestID: requestID,
        body: {
          status,
        },
      });
    },
    [selectedCompany?.cid, selectedShareID, updateForSaleRequestStatus],
  );

  useEffect(() => {
    if (shareInterestsData?.metadata) {
      const { total } = shareInterestsData.metadata;
      setPaginationParams((prev) => ({ ...prev, total }));
    }
  }, [shareInterestsData?.metadata]);

  return (
    <StyledDrawer className="drawer" size="large" closable={false} onClose={handleDrawerClose} open={isOpen}>
      <Flex className="drawer-header" justify="space-between" align="center">
        <Flex gap={4} vertical>
          <Text
            style={{
              fontSize: themeToken.fontSizeLG,
              color: themeToken['branding-primary-6'],
              fontWeight: 600,
            }}
          >
            {t('interestedUsers')}
          </Text>
          {isShareInterestsLoading ? (
            <Skeleton.Button active size="small" shape="round" block style={{ height: 16 }} />
          ) : (
            <Text
              style={{
                color: themeToken['branding-natural-6'],
                fontSize: themeToken.fontSize,
              }}
            >
              {`${paginationParams.total} ${paginationParams.total > 1 ? t('usersInterested') : t('userInterested')}`}
            </Text>
          )}
        </Flex>
      </Flex>
      <Divider
        style={{
          marginBlock: 0,
          borderColor: themeToken['branding-natural-1'],
        }}
      />
      <Flex className="drawer-body" gap={12} vertical>
        {shareInterestsData?.data.map((el) => (
          <InterestedUserItem
            key={el.id}
            shareInterest={el}
            handleOpenFinalStepModal={handleModalOpen}
            handleUpdateForSaleRequestStatus={handleUpdateForSaleRequestStatus}
            isUpdateForSaleRequestStatusLoading={isUpdateForSaleRequestStatusLoading}
          />
        ))}
        <StyledPagination
          current={paginationParams.current}
          total={paginationParams.total}
          onChange={onPaginationChange}
          style={{
            alignSelf: 'self-end',
          }}
        />
        <StyledButton
          type="primary"
          onClick={handleDrawerClose}
          style={{
            marginTop: 'auto',
          }}
        >
          {t('close')}
        </StyledButton>
      </Flex>
      {selectedInterestID && (
        <FinalStepModal
          isOpen={isFinalStepModalOpen}
          handleCancel={handleModalClose}
          forSaleShareID={selectedShareID}
          selectedInterestID={selectedInterestID}
        />
      )}
    </StyledDrawer>
  );
};

export default InterestedDetailsDrawer;
