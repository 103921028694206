export default {
  marketPlace: 'Market Place',
  allMarketActivities: 'All Market Activities',
  imInterested: "I'm interested",
  viewsellinfo: 'View sell info',
  sharesNumber: 'Shares Number',
  pricePerShare: 'Price Per Share',
  totalCost: 'Total Cost',
  sellInformation: 'Sell information',
  shareOwnership: 'Share ownership',
  email: 'Email',
  phoneNumber: 'Phone number',
  equityClass: 'Equity class',
  numberOfShares: 'Number of Shares',
  transactionType: 'Transaction type',
  sell: 'Sell',
  total: 'Total',
};
