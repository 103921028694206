import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { App } from 'antd';

import { queryClient } from '@/lib/react-query';
import { axiosClient } from '@/lib/axios';

type SharesPayload = {
  companyID?: number;
  requestID: number;
  body: {
    status: 'canceled' | 'deleted';
  };
};

type SharesResponse = {
  message: string;
};
export const updateSentRequestStatus = async ({
  companyID,
  requestID,
  body,
}: SharesPayload): Promise<SharesResponse> => {
  const { data } = await axiosClient.put<SharesResponse>(
    `/companies/${companyID}/cap-table/requests/sent/${requestID}`,
    body,
  );

  return data;
};
type UseUpdateSentRequestStatusOptions = {
  companyID?: number;
  config?: Partial<UseMutationOptions<SharesResponse, AxiosError<{ message: string }>, SharesPayload>>;
  postSuccess?: () => void;
};

export const useUpdateSentRequestStatus = ({ companyID, config, postSuccess }: UseUpdateSentRequestStatusOptions) => {
  const { notification: toaster } = App.useApp();
  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.message,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['requests-history', companyID],
      });
      toaster.success({
        message: 'Updated Successfully',
        description: 'Request Status Updated Successfully',
      });
      postSuccess?.();
    },
    ...config,
    mutationFn: updateSentRequestStatus,
  });
};
