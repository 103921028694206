import { useTranslation } from 'react-i18next';
import { Avatar, Flex, Typography } from 'antd';

import { formatNumber } from '@/utils/formatNumber';
import CommonModal from '@/features/captable/components/Shared/CommonModal';
import { StyledDivider } from '@/components/Shared/Divider';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { CellSkeleton } from '@/components/Shared/CellSkeleton';
import { getNameInitials } from '@/utils/getNameInitials';
import themeToken from '@lib/theme/tokens/index';

import { MarketPlaceInfoContainer } from './InfoContainer';
import { useGetShareSale } from '../api/getShareSale';

const { Title, Text } = Typography;

type Props = {
  isModalOpen: boolean;
  handleModalCancel: () => void;
  selectedShare: number;
};

const SellInformationModal = ({ isModalOpen, handleModalCancel, selectedShare }: Props) => {
  const { t } = useTranslation('marketPlace');
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const { data: shareSaleData, isLoading: isShareSaleLoading } = useGetShareSale({
    companyID: selectedCompany?.cid,
    saleShareID: selectedShare,
  });

  return (
    <CommonModal isModalOpen={isModalOpen} handleCancel={handleModalCancel}>
      <Title
        style={{
          color: themeToken.colorPrimary,
        }}
        level={5}
      >
        {t('sellInformation')}
      </Title>
      <StyledDivider
        style={{
          marginBlock: themeToken.size,
        }}
      />
      <Flex gap={8} vertical>
        <Flex align="center" gap={8}>
          {isShareSaleLoading ? (
            <CellSkeleton shape="square" height="32px" width="32px" block={false} />
          ) : (
            <Avatar shape="square" size={32} style={{ backgroundColor: themeToken.colorPrimary, borderRadius: 1 }}>
              {getNameInitials(shareSaleData?.company_user.name ?? '')}
            </Avatar>
          )}
          <Flex vertical>
            {isShareSaleLoading ? (
              <CellSkeleton />
            ) : (
              <Text
                style={{
                  color: themeToken['table-color-text'],
                  fontSize: themeToken.fontSize,
                  fontWeight: 600,
                }}
              >
                {shareSaleData?.company_user.name}
              </Text>
            )}
            <Text
              style={{
                color: themeToken['branding-natural-6'],
                fontSize: themeToken.fontSizeSM,
                fontWeight: 600,
              }}
            >
              {t('shareOwnership')}
            </Text>
          </Flex>
        </Flex>
        <MarketPlaceInfoContainer vertical>
          <Flex align="center" justify="space-between">
            <Text
              style={{
                color: '#271060',
                fontSize: themeToken.fontSizeSM,
              }}
            >
              {t('email')}
            </Text>
            {isShareSaleLoading ? (
              <CellSkeleton block={false} width="100px" />
            ) : (
              <Text
                style={{
                  color: '#271060',
                  fontSize: themeToken.fontSizeSM,
                  fontWeight: 600,
                }}
              >
                {shareSaleData?.company_user.mail}
              </Text>
            )}
          </Flex>
          <Flex align="center" justify="space-between">
            <Text
              style={{
                color: '#271060',
                fontSize: themeToken.fontSizeSM,
              }}
            >
              {t('phoneNumber')}
            </Text>
            {isShareSaleLoading ? (
              <CellSkeleton block={false} width="100px" />
            ) : (
              <Text
                style={{
                  color: '#271060',
                  fontSize: themeToken.fontSizeSM,
                  fontWeight: 600,
                }}
              >
                {shareSaleData?.company_user.mobile}
              </Text>
            )}
          </Flex>
        </MarketPlaceInfoContainer>
        <MarketPlaceInfoContainer vertical>
          <Flex align="center" justify="space-between">
            <Text
              style={{
                color: '#271060',
                fontSize: themeToken.fontSizeSM,
              }}
            >
              {t('equityClass')}
            </Text>
            {isShareSaleLoading ? (
              <CellSkeleton block={false} width="100px" />
            ) : (
              <Text
                style={{
                  color: '#271060',
                  fontSize: themeToken.fontSizeSM,
                  fontWeight: 600,
                }}
              >
                {shareSaleData?.equity_class}
              </Text>
            )}
          </Flex>
          <Flex align="center" justify="space-between">
            <Text
              style={{
                color: '#271060',
                fontSize: themeToken.fontSizeSM,
              }}
            >
              {t('numberOfShares')}
            </Text>
            {isShareSaleLoading ? (
              <CellSkeleton block={false} width="100px" />
            ) : (
              <Text
                style={{
                  color: '#271060',
                  fontSize: themeToken.fontSizeSM,
                  fontWeight: 600,
                }}
              >
                {formatNumber(shareSaleData?.number_of_shares ?? 0)}
              </Text>
            )}
          </Flex>
          <Flex align="center" justify="space-between">
            <Text
              style={{
                color: '#271060',
                fontSize: themeToken.fontSizeSM,
              }}
            >
              {t('transactionType')}
            </Text>
            <Text
              style={{
                color: '#271060',
                fontSize: themeToken.fontSizeSM,
                fontWeight: 600,
              }}
            >
              {t('sell')}
            </Text>
          </Flex>
          <Flex
            align="center"
            justify="space-between"
            style={{
              background: themeToken['branding-primary-1'],
              padding: `${themeToken.paddingSM}px 10px`,
            }}
          >
            <Text
              style={{
                color: '#271060',
                fontSize: themeToken.fontSizeSM,
              }}
            >
              {t('total')}
            </Text>
            {isShareSaleLoading ? (
              <CellSkeleton block={false} width="100px" />
            ) : (
              <Text
                style={{
                  color: '#271060',
                  fontSize: themeToken.fontSizeSM,
                  fontWeight: 600,
                }}
              >
                {formatNumber(shareSaleData?.total_price ?? 0)}
              </Text>
            )}
          </Flex>
        </MarketPlaceInfoContainer>
      </Flex>
    </CommonModal>
  );
};

export default SellInformationModal;
