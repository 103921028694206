import axiosClient from '@/lib/axios';
import { TransactionRequestDetails } from '../types';
import { QueryFunction, useQuery } from '@tanstack/react-query';

type TransactionRequestDetailsParams = {
  companyID: number;
  transctionRequestId: number;
};

type TransactionRequestDetailsResponse = {
  data: TransactionRequestDetails;
};

export const getTransactionRequestDetails = async ({
  companyID,
  transctionRequestId,
}: TransactionRequestDetailsParams): Promise<TransactionRequestDetails | void> => {
  const { data } = await axiosClient.get<TransactionRequestDetailsResponse>(
    `/companies/${companyID}/cap-table/requests/received/${transctionRequestId}`,
  );

  return data.data;
};

type QueryFnType = typeof getTransactionRequestDetails;

type UseGetTransactionRequestDetailsOptions = {
  companyID: number;
  transctionRequestId: number;
  config?: QueryFunction<QueryFnType>;
};

export const useGetTransactionRequestDetails = ({
  companyID,
  transctionRequestId,
  config,
}: UseGetTransactionRequestDetailsOptions) => {
  return useQuery({
    queryKey: ['transaction-request-details', companyID, transctionRequestId],
    queryFn: () => getTransactionRequestDetails({ companyID, transctionRequestId }),
    ...config,
  });
};
