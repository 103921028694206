import { Route, Routes } from 'react-router-dom';

import AuthorizedRoute from '@/components/AuthorizedRoute';
import AddNewCompany from '../views/AddNewCompany';
import ExpiryChecker from '@/components/ExpiryChecker';
import { Suspense, lazy } from 'react';
import Dashboard from '../views/Dashboard';
import InvestmentOverview from '../views/InvestmentOverview';
import FallbackSpin from '@/components/Shared/FallbackSpin';
import TransactionRequests from '../views/TransactionRequests';
import MyActivity from '../views/MyActivity';
import CompanyFlagRoute from '@/components/CompanyFlagRoute';

const EditCompany = lazy(() => import('../views/EditCompany'));

export const CompaniesRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        <Route element={<AuthorizedRoute allowedPermissions={['company.dashboard']} />}>
          <Route element={<ExpiryChecker />}>
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
        </Route>
        <Route element={<AuthorizedRoute allowedPermissions={['company.my-investment']} />}>
          <Route element={<ExpiryChecker />}>
            <Route path="investment-overview">
              <Route path="my-investments" element={<InvestmentOverview />} />
              <Route
                path="transaction-requests"
                element={<CompanyFlagRoute allowedFlag="is_admin" element={<TransactionRequests />} />}
              />
              <Route
                path="my-activity"
                element={<CompanyFlagRoute allowedFlag="can_trade_shares" element={<MyActivity />} />}
              />
            </Route>
          </Route>
        </Route>
        <Route path="new" element={<AddNewCompany />} />
        <Route path=":id/edit" element={<EditCompany />} />
      </Routes>
    </Suspense>
  );
};
