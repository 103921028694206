import { List } from 'antd';
import styled from 'styled-components';

import themeToken from '@lib/theme/tokens/index';

export const MarketPlaceList = styled(List)`
  &.ant-list {
    .ant-list-items {
      display: flex;
      flex-direction: column;
      gap: 16px;
      & > .ant-list-item {
        border: 1px solid #0000000f;
        border-radius: ${themeToken.borderRadiusLG}px;
        padding: ${themeToken.padding}px;
        & > .ant-flex {
          width: 100%;
          & > .ant-list-item-meta {
            display: flex;
            align-items: center;
            .ant-list-item-meta-title {
              margin: 0;
            }
          }
        }
      }
    }
  }
`;
