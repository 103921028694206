export default {
  header: {
    goPremium: 'احصل على النسخة المميزة',
    exploreDemo: 'استكشف النسخة التجريبية',
    searchFor: 'ابحث عن',
    myProfile: 'ملفي الشخصي',
    signOut: 'تسجيل الخروج',
  },
  sidebar: {
    dashboard: 'نظرة عامة',
    investmentOverview: {
      title: 'استثماراتي',
      myInvestments: 'استثماراتي',
      transactionRequests: 'طلبات العمليات',
      myActivity: 'نشاطاتي',
    },
    editCompany: 'تعديل الشركة',
    mainMenu: 'القائمة الرئيسية',
    companyReviews: 'تقارير الشركة',
    scenarioModeling: {
      parentTitle: 'النمذجة',
      investmentRound: 'نمذجة لجولة الاستثمار',
      exitOrliquidModeling: 'نمذجة التخارج',
    },

    captable: {
      title: 'جدول الملكية',
      captableOverview: 'نظرة عامة',
      equities: 'الاسهم',
      options: 'خطط خيارات الاسهم',
      convertibleInstruments: 'السندات القابلة للتحويل',
      vestingSchedule: 'جدول استحقاق الملكية',
      transactionsHistory: 'سجل العمليات',
    },
    valuation: {
      title: 'التقييم',
      dashboard: 'لوحة التحكم',
      questionnaire: 'الاستبيان',
      financial: 'البيانات المالية',
      reports: 'التقارير',
    },
    financialTools: ' أدوات مالية',
    governance: 'الحوكمة',
    dataRoom: 'غرفة البيانات',
    addOns: 'الإضافات',
    support: 'الدعم',
    docGen: 'العقود',
    usersManagement: {
      title: 'إدارة المساهمين',
      usersList: 'قائمة المساهمين',
      rolesAndPermissions: 'صلاحيات الوصول',
      activityLogs: 'سجل العمليات',
    },
    marketPlace: 'السوق التجارية',
    consultationServices: 'خدمات استشارية',
  },
  notifications: {
    markAllAsRead: 'علّم الكل كمقروء',
    noNotifications: 'لا توجد إشعارات',
    deleteWarning: 'هل أنت متأكد من حذف جميع الإشعارات؟',
    deleteWarningTitle: 'حذف جميع الإشعارات',
    confirm: 'تأكيد',
  },
};
