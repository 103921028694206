export default {
  header: {
    goPremium: 'Go Premium',
    exploreDemo: 'Explore Demo',
    searchFor: 'Search For',
    myProfile: 'My Profile',
    signOut: 'Sign out',
  },
  sidebar: {
    dashboard: 'Dashboard',
    investmentOverview: {
      title: 'Personal Holdings',
      myInvestments: 'My Investments',
      transactionRequests: 'Transaction Requests',
      myActivity: 'My Activity',
    },
    editCompany: 'Edit Company',
    mainMenu: 'Main Menu',
    companyReviews: 'Company Reviews',

    scenarioModeling: {
      parentTitle: 'Scenario Modeling',
      investmentRound: 'Investment Round',
      exitOrliquidModeling: 'Exit Modeling',
    },

    captable: {
      title: 'Captable',
      captableOverview: 'Captable Overview',
      equities: 'Equities',
      options: 'Option Pools',
      convertibleInstruments: 'Convertible Instruments',
      vestingSchedule: 'Vesting Schedule',
      transactionsHistory: 'Transactions History',
    },

    valuation: {
      title: 'Valuation',
      dashboard: 'Dashboard',
      questionnaire: 'Questionnaire',
      financial: 'Financial',
      reports: 'Reports',
    },

    financialTools: 'Financial Tools',
    governance: 'Governance',
    dataRoom: 'Data Room',
    addOns: 'ADD-ONS',
    docGen: 'Contracts',
    support: 'Support',
    usersManagement: {
      title: 'Shareholders',
      usersList: 'Shareholders List',
      rolesAndPermissions: 'Access Levels',
      activityLogs: 'Activity Logs',
    },
    marketPlace: 'Market Place',
    consultationServices: 'Consultation Services',
  },
  notifications: {
    markAllAsRead: 'Mark all as read',
    noNotifications: 'There are no notifications',
    deleteWarning: 'Are you sure you want to delete all notifications?',
    deleteWarningTitle: 'Delete All Notifications',
    confirm: 'Confirm',
  },
};
