import { QueryFunction, useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/lib/axios';

import type { PersonalHoldings } from '../types';

type CompanyPayload = {
  companyID: number;
};

type CompanyResponse = {
  data: PersonalHoldings;
};

export const getPersonalHoldings = async ({ companyID }: CompanyPayload): Promise<PersonalHoldings> => {
  const { data } = await axiosClient.get<CompanyResponse>(`/companies/${companyID}/dashboard/personal-holdings
  `);

  return data?.data;
};
type QueryFnType = typeof getPersonalHoldings;

type UseGetPersonalHoldingsOptions = {
  companyID: number;
  config?: QueryFunction<QueryFnType>;
};

export const useGetPersonalHoldings = ({ companyID, config }: UseGetPersonalHoldingsOptions) => {
  return useQuery({
    queryKey: ['personal-holdings', companyID],
    queryFn: () => getPersonalHoldings({ companyID }),
    ...config,
  });
};
