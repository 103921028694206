export default {
  marketPlace: 'السوق التجارية',
  allMarketActivities: 'جميع سجلات السوق',
  imInterested: 'أنا مهتم',
  viewsellinfo: 'عرض معلومات البيع',
  sharesNumber: 'عدد الأسهم',
  pricePerShare: 'سعر السهم',
  totalCost: 'التكلفة الإجمالية',
  sellInformation: 'معلومات البيع',
  shareOwnership: 'ملكية الأسهم',
  email: 'البريد الإلكتروني',
  phoneNumber: 'رقم الهاتف',
  equityClass: 'فئة الأسهم',
  numberOfShares: 'عدد الأسهم',
  transactionType: 'نوع المعاملة',
  sell: 'بيع',
  total: 'الإجمالي',
};
