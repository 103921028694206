import styled from 'styled-components';
import { List } from 'antd';

import themeToken from '@lib/theme/tokens/index';

export const StyledListItem = styled(List.Item)`
  &.ant-list-item {
    @media (max-width: ${themeToken.screenMD}px) {
      flex-wrap: wrap;
      gap: 16px;
    }
    & > .ant-flex {
      gap: 36px;
      @media (max-width: ${themeToken.screenMD}px) {
        flex-direction: column;
        gap: 16px;
      }
      & > .ant-list-item-meta {
        width: 100%;
      }
    }
    & > .ant-list-item-action {
      @media (max-width: ${themeToken.screenMD}px) {
        margin: 0;
      }
    }
  }
`;
