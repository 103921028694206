import { axiosClient } from '@/lib/axios';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import type { Metadata, PaginationParams } from '@/types';
import type { UserData } from '../../usersManagement/types';

type TranferShareholdersResponseData = {
  data: UserData[];
  metadata: Metadata;
};

type TranferShareholdersParams = {
  companyID: number;
  queryParams?: {
    sort_by?: keyof UserData;
    sort_dir?: 'asc' | 'desc';
    q?: string;
  } & PaginationParams;
};

export const getTranferShareholders = async ({
  companyID,
  queryParams,
}: TranferShareholdersParams): Promise<TranferShareholdersResponseData> => {
  const { data } = await axiosClient.get<TranferShareholdersResponseData>(
    `/companies/${companyID}/cap-table/equities/issued-shares/transfer/users/`,
    {
      params: queryParams,
    },
  );
  return data;
};
type QueryFnType = typeof getTranferShareholders;

type UseGetTranferShareholdersResponseDataOptions = {
  companyID: number;
  queryParams?: TranferShareholdersParams['queryParams'];
  config?: QueryFunction<QueryFnType>;
};

export const useGetTransferShareholders = ({
  companyID,
  queryParams,
  config,
}: UseGetTranferShareholdersResponseDataOptions) => {
  return useQuery({
    queryKey: ['users', companyID, queryParams],
    queryFn: () => getTranferShareholders({ companyID, queryParams }),
    ...config,
  });
};
