import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Typography } from 'antd';
import themeToken from '@lib/theme/tokens/index';
import { StyledButton } from '@/lib/theme/components/Button';
import { CellSkeleton } from '@/components/Shared/CellSkeleton';
import EditIcon from '@/components/Icons/EditIcon';
import DeleteIcon from '@/components/Icons/DeleteIcon';
import DownloadIcon from '../Icons/DownloadIcon';
import SharedModal from './SharedModal';
import { useTrackEvent } from '@/hooks/useTrackEvents';

type Props = {
  isDeleteAction?: boolean;
  isEditAction?: boolean;
  isSecondaryBtn?: boolean;
  id?: number;
  pageHref?: string;
  secondaryBtnTxt?: string;
  secondaryBtnHref?: string;
  secondaryBtnDisabled?: boolean;
  isDeleteBtnDisabled?: boolean;
  SecondaryBtnIcon?: () => JSX.Element;
  onDelete?: () => void;
  onDownload?: () => void;
  isLoading: boolean;
  actionsCount: number;
  deletedItemName?: string;
  isDownloadAction?: boolean;
  isDownloadButtonPending?: boolean;
  locationState?: { from: string };
};

const { Text } = Typography;

const ActionsSkeleton = () => {
  return (
    <Flex gap={2}>
      <CellSkeleton shape="circle" width="16px" />
      <CellSkeleton width="64px" />
    </Flex>
  );
};

const CommonActionsBtns = ({
  isDeleteAction = true,
  isEditAction = true,
  id,
  pageHref,
  onDelete,
  onDownload,
  isLoading,
  actionsCount,
  isDownloadAction,
  deletedItemName,
  isSecondaryBtn,
  secondaryBtnTxt,
  secondaryBtnHref,
  secondaryBtnDisabled = false,
  isDeleteBtnDisabled = false,
  SecondaryBtnIcon,
  isDownloadButtonPending,
  locationState,
}: Props) => {
  const navigate = useNavigate();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const { t } = useTranslation('common');
  const { sendMixpanelEvent } = useTrackEvent({ isGeneralEvent: true });

  if (isLoading)
    return (
      <Flex gap={8}>
        {Array(actionsCount)
          .fill(0)
          .map((_, index) => (
            <ActionsSkeleton key={index} />
          ))}
      </Flex>
    );

  return (
    <Flex
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {isEditAction && (
        <StyledButton
          style={{
            padding: `${(themeToken.paddingXXS, themeToken.paddingXS)}`,
            color: themeToken.colorPrimary,
          }}
          size="small"
          type="link"
          icon={<EditIcon stroke="#2a186a" />}
          onClick={() => {
            navigate(`${pageHref}/edit/${id}`);
          }}
        >
          {t('edit')}
        </StyledButton>
      )}
      {isSecondaryBtn && (
        <StyledButton
          style={{
            padding: `${themeToken.paddingXXS}px ${themeToken.paddingXS}px`,
            color: themeToken.colorPrimary,
          }}
          size="small"
          type="link"
          icon={SecondaryBtnIcon && <SecondaryBtnIcon />}
          onClick={(e) => {
            e.stopPropagation();
            sendMixpanelEvent(`${secondaryBtnTxt?.split(' ').join('-').toLowerCase()}-btn-clicked`);
            navigate(`${pageHref}${secondaryBtnHref}`, {
              state: locationState,
            });
          }}
          disabled={secondaryBtnDisabled}
        >
          {secondaryBtnTxt}
        </StyledButton>
      )}
      {isDeleteAction && (
        <>
          <StyledButton
            style={{
              padding: `${(themeToken.paddingXXS, themeToken.paddingXS)}`,
              color: themeToken.colorPrimary,
            }}
            size="small"
            type="link"
            icon={<DeleteIcon />}
            onClick={() => {
              sendMixpanelEvent('delete-btn-clicked');
              setConfirmDeleteOpen(true);
            }}
            disabled={isDeleteBtnDisabled}
          >
            {t('delete')}
          </StyledButton>
          <SharedModal
            open={confirmDeleteOpen}
            setConfirmDeleteOpen={setConfirmDeleteOpen}
            onOk={onDelete}
            confirmationText={t('yesDelete')}
            title={t('deleteWarning')}
            content={
              <Text>
                {t('deleteConfirmation')} <span style={{ fontWeight: 'bold' }}> {deletedItemName} </span> ?{' '}
                {t('impactCapTableWarning')}
              </Text>
            }
          />
        </>
      )}
      {isDownloadAction && (
        <StyledButton
          style={{
            padding: `${(themeToken.paddingXXS, themeToken.paddingXS)}`,
            color: themeToken.colorPrimary,
          }}
          size="small"
          type="link"
          icon={<DownloadIcon />}
          onClick={() => {
            if (onDownload) {
              sendMixpanelEvent('download-btn-clicked');
              onDownload();
            }
          }}
          loading={isDownloadButtonPending}
        >
          {t('download')}
        </StyledButton>
      )}
    </Flex>
  );
};

export default CommonActionsBtns;
