import React from 'react';
import { Flex, Grid, Typography } from 'antd';

import { ScenarioModelingSpace } from '@/features/scenarioModeling/components/Shared/Space';
import FinancialMetricDisplay from '@/features/scenarioModeling/components/Shared/FinancialMetricDisplay';
import { StyledDivider } from '@/components/Shared/Divider';
import themeToken from '@lib/theme/tokens/index';

import DashboardCard from './DashboardCard';

const { useBreakpoint } = Grid;
const { Title } = Typography;

type Props = {
  icon?: React.ReactNode;
  title: string;
  metrics: { title: string; value: string }[];
};

const PageHeaderWithMetrics = ({ icon, title, metrics }: Props) => {
  const screens = useBreakpoint();

  return (
    <DashboardCard>
      <Flex gap={12} vertical>
        <Flex align="center" gap={5}>
          {icon}
          <Title
            style={{
              color: themeToken.colorPrimary,
              marginInlineStart: '5px',
              fontSize: '14px',
            }}
          >
            {title}
          </Title>
        </Flex>

        <ScenarioModelingSpace
          direction={screens.md ? 'horizontal' : 'vertical'}
          size={screens.md ? 12 : 24}
          split={
            screens.md && (
              <StyledDivider
                type="vertical"
                style={{
                  height: '46px',
                  margin: 0,
                }}
              />
            )
          }
          align="center"
          style={{
            width: '100%',
            justifyContent: 'space-between',
            background: 'none',
          }}
        >
          {metrics.map((metric, i) => (
            <FinancialMetricDisplay key={i} title={metric.title} value={metric.value} />
          ))}
        </ScenarioModelingSpace>
      </Flex>
    </DashboardCard>
  );
};

export default PageHeaderWithMetrics;
