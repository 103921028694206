import { Flex, Tag } from 'antd';
import type { TFunction } from 'i18next';
import type { ColumnsType } from 'antd/es/table';

import withLoadingSkeleton from '@/HOCs/withLoadingSkeleton';
import { formatNumber } from '@/utils/formatNumber';
import { StyledButton } from '@/lib/theme/components/Button';
import CommonActionsBtns from '@/components/Shared/CommonActionsBtns';
import themeToken from '@lib/theme/tokens/index';

import type { RequestsHistory } from '../types';

export const transactionsHistoryColumns = (
  isLoading: boolean,
  t: TFunction<'captable', 'myActivity'>,
  currency: string,
  handleDrawerOpen: (transactionID: number) => void,
  handleUpdateSentRequestStatus: ({ requestID, status }: { requestID: number; status: 'canceled' | 'deleted' }) => void,
): ColumnsType<RequestsHistory | Record<string, number>> => [
  {
    title: t('type'),
    dataIndex: 'type',
    render: withLoadingSkeleton(isLoading, (val: string) => t(val)),
  },
  {
    title: t('status'),
    dataIndex: 'status',
    render: withLoadingSkeleton(isLoading, (val: string) => {
      let color = '';
      switch (val) {
        case 'pending':
          color = 'orange';
          break;
        case 'rejected':
          color = 'red';
          break;
        case 'approved':
          color = themeToken.colorPrimary;
          break;
        case 'canceled':
          color = 'purple';
          break;
        case 'closed':
          color = 'green';
          break;
        default:
          break;
      }
      return <Tag color={color}>{t(val)}</Tag>;
    }),
  },
  {
    title: t('numberOfShares'),
    dataIndex: 'number_of_shares',
    render: withLoadingSkeleton(isLoading, (val: number) => formatNumber(val)),
  },
  {
    title: t('total'),
    dataIndex: 'total_price',
    render: withLoadingSkeleton(isLoading, (val: number) => `${formatNumber(val)} ${currency}`),
  },
  {
    title: t('pricePerShare'),
    dataIndex: 'share_price',
    render: withLoadingSkeleton(
      isLoading,
      (val: number) => `${formatNumber(val, { maximumFractionDigits: 4 })} ${currency}`,
    ),
  },
  {
    title: t('interested'),
    dataIndex: 'number_of_interests',
    width: 250,
    render: withLoadingSkeleton(isLoading, (val: number, record) =>
      record?.type === 'for_sale_share' ? (
        <Flex align="center" gap={8} justify="space-between">
          <span>{formatNumber(val)}</span>
          <StyledButton
            type="text"
            size="small"
            onClick={() => handleDrawerOpen(record?.entity_id)}
            style={{
              color: themeToken.colorPrimary,
            }}
          >
            {t('seeInterested')}
          </StyledButton>
        </Flex>
      ) : (
        <></>
      ),
    ),
  },

  {
    title: t('actions'),
    render: withLoadingSkeleton(isLoading, (_, record) => (
      <CommonActionsBtns
        id={record?.id}
        actionsCount={1}
        isLoading={isLoading}
        onDelete={() => handleUpdateSentRequestStatus({ requestID: record?.id as number, status: 'deleted' })}
        deletedItemName={record?.type as string}
        isEditAction={false}
      />
    )),
  },
];
