import axiosClient from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { App } from 'antd';

export type AddSellSharePayload = {
  companyID: number;
  payload: {
    number_of_shares: number;
    share_price: number;
    issue_share_id: number;
  };
};

type AddSellShareResponse = {
  message: string;
};

export const addSellShare = async ({ companyID, payload }: AddSellSharePayload): Promise<AddSellShareResponse> => {
  const { data } = await axiosClient.post<AddSellShareResponse>(
    `/companies/${companyID}/cap-table/equities/issued-shares/for-sale`,
    payload,
  );
  return data;
};

type UseAddSellShareOptions = {
  config?: MutationFunction<typeof addSellShare>;
  postSuccess?: () => void;
};

export const useAddSellShare = ({ config, postSuccess }: UseAddSellShareOptions) => {
  const { notification: toaster } = App.useApp();

  return useMutation({
    onError: (error) => {
      const errorResponse = (error.response?.data as { message: string }) || {};
      toaster.error({
        message: 'Something Went Wrong',
        description: errorResponse.message || error.message,
      });
    },
    onSuccess: async (res, params) => {
      await queryClient.invalidateQueries({
        queryKey: ['investment-overview', params.companyID],
      });
      toaster.success({
        message: 'Added Successfully',
        description: res.message,
      });
      postSuccess?.();
    },
    mutationFn: addSellShare,
    ...config,
  });
};
