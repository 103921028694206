import login from '@/features/auth/locales/ar/login';
import common from './common';
import layout from './layout';
import captable from './captable';
import signup from '@/features/auth/locales/ar/signup';
import confirmAccount from '@/features/auth/locales/ar/confirmAccount';
import valuation from './valuation';
import modeling from './modeling';
import dataroom from './dataroom';
import usersManagement from './usersManagement';
import company from './company';
import profile from './profile';
import plans from './plans';
import consultation from './consultation';
import pageTitles from './pageTitles';
import companyListing from './companyListing';
import documentGenerator from './documentGenerator';
import companyReviews from './companyReviews';
import marketPlace from './marketPlace';

export default {
  login,
  common,
  signup,
  confirmAccount,
  layout,
  captable,
  valuation,
  modeling,
  dataroom,
  usersManagement,
  company,
  profile,
  plans,
  consultation,
  pageTitles,
  companyListing,
  documentGenerator,
  companyReviews,
  marketPlace,
};
