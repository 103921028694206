import axiosClient from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { App } from 'antd';

export type AddTransferSharePayload = {
  companyID: number;
  payload: {
    number_of_shares: number;
    shareholder_id: number;
    issue_share_id: number;
  };
};

type AddTransferShareResponse = {
  message: string;
};

export const addTransferShare = async ({
  companyID,
  payload,
}: AddTransferSharePayload): Promise<AddTransferShareResponse> => {
  const { data } = await axiosClient.post<AddTransferShareResponse>(
    `/companies/${companyID}/cap-table/equities/issued-shares/transfer`,
    payload,
  );
  return data;
};

type UseAddTransferShareOptions = {
  config?: MutationFunction<typeof addTransferShare>;
  postSuccess?: () => void;
};

export const useAddTransferShare = ({ config, postSuccess }: UseAddTransferShareOptions) => {
  const { notification: toaster } = App.useApp();

  return useMutation({
    onError: (error) => {
      const errorResponse = (error.response?.data as { message: string }) || {};
      toaster.error({
        message: 'Something Went Wrong',
        description: errorResponse.message || error.message,
      });
    },
    onSuccess: async (res, params) => {
      await queryClient.invalidateQueries({
        queryKey: ['investment-overview', params.companyID],
      });
      toaster.success({
        message: 'Added Successfully',
        description: res.message,
      });
      postSuccess?.();
    },
    mutationFn: addTransferShare,
    ...config,
  });
};
